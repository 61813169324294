import { ComponentMap } from './types';
import { objPathToTitle } from './title';

const editorType = 'austrac.iftie.beneficiaryInstn';
const title = objPathToTitle;

interface FormModel {
  code: string;
}

export interface BeneficiaryInstnModel {
  institution?: {
    code: string;
  };
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function toFormSchema(data: string, readonly = false): object {
  return {
    elements: {
      code: {
        type: 'text',
        label: 'Code',
        required: true,
        placeholder: 'Empty value',
        readonly,
      },
    },
  };
}

export function fromFormModel(formModel: FormModel): BeneficiaryInstnModel {
  return {
    institution: {
      code: formModel.code,
    },
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function toFormModel(data: string): FormModel {
  return { code: '' };
}

export const componentMap: ComponentMap = {
  [editorType]: {
    title,
    toFormModel,
    fromFormModel,
    toFormSchema,
  },
};

export default componentMap;
