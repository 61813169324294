






















import Vue from 'vue';
import { I8Icon } from 'i8-ui';

// Icon library
import { faSmileBeam } from '@fortawesome/pro-light-svg-icons/faSmileBeam';
import { faLaughBeam } from '@fortawesome/pro-light-svg-icons/faLaughBeam';
import { faFrown } from '@fortawesome/pro-light-svg-icons/faFrown';
import { faSmile } from '@fortawesome/pro-light-svg-icons/faSmile';
import { faTreeChristmas } from '@fortawesome/pro-light-svg-icons/faTreeChristmas';
import { faGifts } from '@fortawesome/pro-light-svg-icons/faGifts';
import { faSleigh } from '@fortawesome/pro-light-svg-icons/faSleigh';
import { faHatSanta } from '@fortawesome/pro-light-svg-icons/faHatSanta';
import { faBells } from '@fortawesome/pro-light-svg-icons/faBells';
import { faCandyCane } from '@fortawesome/pro-light-svg-icons/faCandyCane';
import { faJackOLantern } from '@fortawesome/pro-light-svg-icons/faJackOLantern';
import { faGhost } from '@fortawesome/pro-light-svg-icons/faGhost';
import { faSpiderWeb } from '@fortawesome/pro-light-svg-icons/faSpiderWeb';
import { faSpider } from '@fortawesome/pro-light-svg-icons/faSpider';
import { faHatWitch } from '@fortawesome/pro-light-svg-icons/faHatWitch';
// Add all icons to the library
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(
  faSmileBeam,
  faLaughBeam,
  faFrown,
  faSmile,
  faTreeChristmas,
  faGifts,
  faSleigh,
  faHatSanta,
  faBells,
  faCandyCane,
  faJackOLantern,
  faGhost,
  faSpiderWeb,
  faSpider,
  faHatWitch,
);

export const PrReportItemEmpty = Vue.extend({
  name: 'pr-report-item-empty',

  components: {
    I8Icon,
  },

  props: {
    status: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      boringMode: false,
      icon: {
        STATUS_IMPORTED: 'smile',
        STATUS_REPORT_PENDING: 'smile-beam',
        STATUS_NOT_REPORTABLE: 'smile',
        STATUS_EXCEPTION: 'frown',
        STATUS_REPORTED: 'laugh-beam',
        STATUS_MISSING_FILES: 'frown',
        STATUS_ERROR: 'frown',
      } as Record<string, string>,
      icon_christmas: {
        STATUS_IMPORTED: 'sleigh',
        STATUS_REPORT_PENDING: 'gifts',
        STATUS_NOT_REPORTABLE: 'sleigh',
        STATUS_EXCEPTION: 'bells',
        STATUS_REPORTED: 'tree-christmas',
        STATUS_MISSING_FILES: 'bells',
        STATUS_ERROR: 'bells',
      } as Record<string, string>,
      icon_halloween: {
        STATUS_IMPORTED: 'hat-witch',
        STATUS_REPORT_PENDING: 'jack-o-lantern',
        STATUS_NOT_REPORTABLE: 'hat-witch',
        STATUS_EXCEPTION: 'spider-web',
        STATUS_REPORTED: 'jack-o-lantern',
        STATUS_MISSING_FILES: 'spider',
        STATUS_ERROR: 'spider',
      } as Record<string, string>,
      msg: {
        STATUS_IMPORTED: 'has been imported',
        STATUS_REPORT_PENDING: 'is ready to be reported',
        STATUS_NOT_REPORTABLE: 'is not reportable',
        STATUS_EXCEPTION: 'has open exceptions.',
        STATUS_REPORTED: 'has been reported!',
        STATUS_MISSING_FILES: 'is awaiting files',
        STATUS_ERROR: 'has an error',
      },
    };
  },

  computed: {
    isChristmas(): boolean {
      const today = new Date();
      const month = today.getMonth() + 1; // Jan is 0
      const day = today.getDate();

      return month === 12 && day >= 24 && day <= 25; // 24th - 25th Dec
    },

    isHalloween(): boolean {
      const today = new Date();
      const month = today.getMonth() + 1; // Jan is 0
      const day = today.getDate();
      return month === 10 && day === 31; // 31st Oct
    },
  },

  methods: {
    statusIcon(status: string): string {
      if (!this.boringMode) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.isChristmas && this.icon_christmas.hasOwnProperty(status)) {
          return this.icon_christmas[status];
        }

        // eslint-disable-next-line no-prototype-builtins
        if (this.isHalloween && this.icon_halloween.hasOwnProperty(status)) {
          return this.icon_halloween[status];
        }
      }

      return this.icon[status];
    },
  },
});
export default PrReportItemEmpty;
