/* eslint-disable @typescript-eslint/ban-types */
import { objPathToTitle } from './title';
import { ComponentMap } from './types';
import {
  fromFormModel as i8FromFormModel,
  toFormModel as i8ToFormModel,
} from 'i8-ui';

const editorType = 'austrac.address.noCountry';
const title = objPathToTitle;

export function toFormSchema(data: object, readonly = false): object {
  return {
    elements: {
      addr: {
        type: 'text',
        label: 'Address',
        placeholder: 'Empty value',
        maxlength: 140,
        readonly,
      },
      suburb: {
        type: 'text',
        label: 'Suburb',
        placeholder: 'Empty value',
        maxlength: 35,
        readonly,
      },
      state: {
        type: 'text',
        label: 'State',
        placeholder: 'Empty value',
        maxlength: 35,
        readonly,
      },
      postcode: {
        type: 'text',
        label: 'Postcode',
        placeholder: 'Empty value',
        maxlength: 15,
        readonly,
      },
    },
  };
}

export function fromFormModel(
  formModel: object,
  sourceData: object = {},
): object {
  return i8FromFormModel(formModel, sourceData);
}

export function toFormModel(data: object): object {
  // users shouldn't be able to edit id fields
  const omitIdProps = true;
  return i8ToFormModel(data, omitIdProps);
}

const componentMap: ComponentMap = {
  [editorType]: {
    title,
    toFormModel,
    fromFormModel,
    toFormSchema,
  },
};

export { componentMap };
export default componentMap;
