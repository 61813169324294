import startCase from 'lodash/startCase';
import takeRight from 'lodash/takeRight';

/**
 * Omit certain props from the path before title generation
 * Tip: Ensure only group props are omitted to ensure the resulting string is sensible.
 * Eg: `t_from.addresses.address[0]` will result in `t_from.addresses.address[0]` -
 *     Which will than be transformed into the title "From: Address 1"
 *     rather than the repetitive "From: Addresses Address 1"
 */
const propsToOmit = ['report_parties', 'addresses', 'phones'];

/**
 * Properties to transform into other strings.
 * Mostly dropping that `t_` from GoAML paths,
 * but it can facilitate more substancial substitutions if need be.
 */
const propsToTransform: Record<string, string> = {
  Pacs8Document: 'Pacs.008',
  Pacs9Document: 'Pacs.009',
  fr: 'From',
  t_conductor: 'Conductor',
  t_from: 'From',
  t_to: 'To',
  t_from_my_client: 'From my client',
  t_to_my_client: 'To my client',
  t_entity: 'Entity',
  t_person: 'Person',
};

// a.b.c => B: C
// a[1].b.c => B: C
// a.b[0].c => B 1: C
// a.b.c[1] => B: C 2
// a.b[0].c[1] => B 1: C 2
// a[1] => A 2
// a.2.3[4] => 2: 3 5
export function objPathToTitle(
  path: string,
  addColon = false,
  propsToKeep = 5,
): string {
  const props = takeRight(path.split('.'), propsToKeep).reduce(
    (filteredProps, prop) => {
      if (propsToOmit.includes(prop)) return filteredProps;
      return filteredProps.concat(propsToTransform[prop] || prop);
    },
    [] as string[],
  );

  const title = props.map((prop: string, idx: number) => {
    // Emoty props need no transformation
    if (prop === '') return prop;

    // Add a colon to the first prop where more than one prop is present
    // This helps generate a title that represents a grouping:
    // Examples:
    //   "Thing: Property of a thing"
    //   "Things: Thing 1"
    //   "Thing 1: Property"
    const colon = addColon && props.length > 1 && idx === 0 ? ':' : '';

    // does this property have an array index? a[0] => true
    const arrIndex = prop.match(/\[(\d*)\]$/);
    if (!arrIndex) {
      return startCase(prop) + colon;
    }

    // property without array index a[0] => a
    const propName = prop.substring(0, arrIndex.index);

    // array index + 1 a[0] => 0
    const index = parseInt(arrIndex[1], 10) + 1;

    return startCase(`${propName} ${index}`) + colon;
  });

  const generatedTitle = title.join(' ').trim();

  return generatedTitle.length ? generatedTitle : '';
}
