














export const PrPageHeader = {
  name: 'i8-quote',

  props: {
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
  },
};
export default PrPageHeader;
