import {
  ComponentMap,
  AustracAUSTRACDate,
  AustracAUSTRACDateFormModel,
} from '@/editor-map/types';
import { objPathToTitle } from '@/editor-map/title';

const editorType = 'austrac.ifti-dra.AUSTRACDate';
const title = objPathToTitle;

export function toFormSchema(
  data: AustracAUSTRACDate,
  readonly = false,
  // eslint-disable-next-line @typescript-eslint/ban-types
): object {
  return {
    elements: {
      date: {
        type: 'date',
        format: 'iso',
        label: 'Date',
        required: true,
        placeholder: 'Empty value',
        options: {
          minDate: '2000-01-01',
          maxDate: '2025-12-31',
        },
        readonly,
      },
    },
  };
}

export function fromFormModel(
  formModel: AustracAUSTRACDateFormModel,
): AustracAUSTRACDate {
  return formModel.date;
}

export function toFormModel(
  data: AustracAUSTRACDate,
): AustracAUSTRACDateFormModel {
  return { date: data };
}

export const componentMap: ComponentMap = {
  [editorType]: {
    title,
    toFormModel,
    fromFormModel,
    toFormSchema,
  },
};

export default componentMap;
