








import Vue from 'vue';

export const PrDecision = Vue.extend({
  name: 'pr-decision',
});

export default PrDecision;
