import {
  ComponentMap,
  AustracName,
  AustracNameFormModel,
} from '@/editor-map/types';
import { objPathToTitle } from '@/editor-map/title';

const editorType = 'austrac.ifti-dra.Name';
const title = objPathToTitle;

// eslint-disable-next-line @typescript-eslint/ban-types
export function toFormSchema(data: AustracName, readonly = false): object {
  return {
    elements: {
      name: {
        label: 'Name',
        type: 'text',
        maxLength: 140,
        required: true,
        placeholder: 'Empty value',
        readonly,
      },
    },
  };
}

export function fromFormModel(formModel: AustracNameFormModel): AustracName {
  return formModel.name;
}

export function toFormModel(data: AustracName): AustracNameFormModel {
  return { name: data };
}

export const componentMap: ComponentMap = {
  [editorType]: {
    title,
    toFormModel,
    fromFormModel,
    toFormSchema,
  },
};

export default componentMap;
