








import Vue from 'vue';

import { I8Progress } from 'i8-ui';

const PrUploading = Vue.extend({
  name: 'pr-uploading',

  components: {
    I8Progress,
  },

  props: {
    value: {
      type: Number,
      required: true,
    },
  },
});

export { PrUploading };
export default PrUploading;
