import {
  ComponentMap,
  AustracElectronicDataSource,
  AustracElectronicDataSourceFormModel,
} from '@/editor-map/types';
import { objPathToTitle } from '@/editor-map/title';

const editorType = 'austrac.ifti-dra.ElectronicDataSource';
const title = objPathToTitle;

export function toFormSchema(
  data: AustracElectronicDataSource,
  readonly = false,
  // eslint-disable-next-line @typescript-eslint/ban-types
): object {
  return {
    elements: {
      electDataSrc: {
        label: 'Name',
        type: 'text',
        maxLength: 70,
        required: true,
        placeholder: 'Empty value',
        readonly,
      },
    },
  };
}

export function fromFormModel(
  formModel: AustracElectronicDataSourceFormModel,
): AustracElectronicDataSource {
  return formModel.electDataSrc;
}

export function toFormModel(
  data: AustracElectronicDataSource,
): AustracElectronicDataSourceFormModel {
  return { electDataSrc: data };
}

export const componentMap: ComponentMap = {
  [editorType]: {
    title,
    toFormModel,
    fromFormModel,
    toFormSchema,
  },
};

export default componentMap;
