
































import Vue from 'vue';
import { I8Object } from 'i8-ui';

import isObject from 'lodash/isObject';
import omit from 'lodash/omit';

export const PrDiff = Vue.extend({
  name: 'pr-diff',

  components: {
    I8Object,
  },

  props: {
    oldVal: {
      type: [Object, String, Array],
      required: true,
    },
    newVal: {
      type: [Object, String, Array],
      required: true,
    },
  },

  methods: {
    isObject,
    omit,
  },
});

export default PrDiff;
