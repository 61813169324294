


















































































// vue
import Vue from 'vue';

import { PrPage } from '@/mixin';
import { I8Accordion, I8Icon } from 'i8-ui';

import { PrException } from '@/component/pr-exception';

import startCase from 'lodash/startCase';

// Icon library
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle';
// Add all icons to the library
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faExclamationTriangle);

interface Exception {
  id: string;
  title?: string;
  message?: string;
  error?: string;
  ignorable: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  extra?: object;
}

export const PrExceptionList = Vue.extend({
  name: 'pr-exception-list',
  mixins: [PrPage],

  components: {
    I8Icon,
    I8Accordion,
    PrException,
  },

  props: {
    exceptions: {
      type: Array as () => Exception[],
      required: true,
    },
    reportItemId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      pageTitle: 'Exceptions',
      string: {
        ['duplicate-transaction']: 'Duplicate Transaction',
      },
    };
  },

  computed: {
    exceptionType() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;
      const type: Record<string, string> = {};

      const exceptions: Exception[] = vm.exceptions;
      for (const exception of exceptions) {
        type[exception.id] = vm.hasError(exception) ? 'danger' : 'warning';
      }

      return type;
    },
  },

  methods: {
    startCase,
    ignoreException(exception: Exception) {
      this.$router.push({
        name: 'report-item.exception.ignore',
        params: {
          reportItemId: this.reportItemId,
          exceptionId: exception.id,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          exceptionMessage: exception.message!,
        },
      });
    },

    hasError(exception: Exception): boolean {
      // eslint-disable-next-line no-prototype-builtins
      return exception.hasOwnProperty('error') && exception.error !== '';
    },
  },
});

export default PrExceptionList;
