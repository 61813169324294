/* eslint-disable @typescript-eslint/ban-types */
import { ComponentMap } from './types';
import { objPathToTitle } from './title';

const editorType = 'austrac.iftie.IdType';
const title = objPathToTitle;

export function toFormSchema(data: string, readonly = false): object {
  return {
    elements: {
      identificationType: {
        type: 'select',
        label: 'Identification Type',
        options: {
          A: { label: 'A - Bank Account' },
          C: { label: 'C - Credit card/debit card' },
          D: { label: "D - Driver's License" },
          P: { label: 'P - Passport' },
          T: { label: 'T - Telephone/fax number' },
          ARNU: { label: 'ARNU - Alien registration/license' },
          CUST: { label: 'CUST - Customer account/ID' },
          BENE: { label: 'BENE - Benefits card/ID' },
          BCNO: { label: 'BCNO - Birth certificate' },
          BUSR: { label: 'BUSR - Business registration/license' },
          EMID: { label: 'EMID - Employee ID' },
          EMPL: { label: 'EMPL - Employee number' },
          IDNT: { label: 'IDNT - Identity card or National identity number' },
          MEMB: { label: 'MEMB - Membership ID' },
          PHOT: { label: 'PHOT - Photo ID' },
          SECU: { label: 'SECU - Security ID' },
          SOSE: { label: 'SOSE - Social security ID' },
          STUD: { label: 'STUD - Student ID' },
          TXID: { label: 'TXID - Tax number/ID' },
        },
        readonly,
      },
    },
  };
}

export function fromFormModel(formModel: Record<string, string>): string {
  return formModel.identificationType;
}

export function toFormModel(data: string): object {
  return {
    identificationType: data,
  };
}

const componentMap: ComponentMap = {
  [editorType]: {
    title,
    toFormModel,
    fromFormModel,
    toFormSchema,
  },
};

export { componentMap };
export default componentMap;
