/* eslint-disable @typescript-eslint/ban-types */
import { objPathToTitle } from './title';
import { ComponentMap } from './types';
import {
  toFormSchema as i8ToFormSchema,
  fromFormModel as i8FromFormModel,
  toFormModel as i8ToFormModel,
} from 'i8-ui';

const editorType = 'unknown';
const title = objPathToTitle;

export function toFormSchema(data: object, readonly = false): object {
  // users shouldn't be able to edit id fields
  const omitIdProps = true;
  return i8ToFormSchema(data, {
    omitIdProps,
    readonly,
    placeholder: 'Empty value',
  });
}

export function fromFormModel(
  formModel: object,
  sourceData: object = {},
): object {
  return i8FromFormModel(formModel, sourceData);
}

export function toFormModel(data: object): object {
  // users shouldn't be able to edit id fields
  const omitIdProps = true;
  return i8ToFormModel(data, omitIdProps);
}

const componentMap: ComponentMap = {
  [editorType]: {
    title,
    toFormModel,
    fromFormModel,
    toFormSchema,
  },
};

export { componentMap };
export default componentMap;
