







































/* eslint-disable @typescript-eslint/ban-types */
import Vue from 'vue';

import { I8Quote } from '@/component/i8-quote';
import { PrDiff } from '@/component/pr-diff';
import { PrException } from '@/component/pr-exception';

import { DECISION_EDIT } from './consts';
import { EditorException } from '../pr-editor-list/types';
import { DecisionEditor } from './types';
import {
  getExceptionFieldNameById,
  getExceptionMessage,
  objPathToTitle,
} from '@/editor-map';

export const DecisionEdit = Vue.extend({
  name: 'decision-edit',

  components: {
    PrDiff,
    I8Quote,
    PrException,
  },

  props: {
    decision: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      decicionType: DECISION_EDIT,
    };
  },

  computed: {
    newVal(): object | string {
      try {
        return JSON.parse(this.decision[this.decicionType].json_value_new);
      } catch (error) {
        return '';
      }
    },

    oldVal(): object | string {
      try {
        return JSON.parse(this.decision[this.decicionType].json_value_old);
      } catch (error) {
        return '';
      }
    },
  },

  methods: {
    exceptionMessage(
      exception: EditorException,
      editor: DecisionEditor,
    ): string {
      const message = exception.message;
      if (message) {
        return message;
      }

      let validationMessage: string | undefined;
      if (Object.prototype.hasOwnProperty.call(exception, 'validation')) {
        validationMessage = this.exceptionValidationMessage(exception, editor);
      }

      if (validationMessage) {
        return validationMessage;
      }

      const path = editor.path.split('.');
      const field = objPathToTitle(path[path.length - 1]) || 'Field';
      return `${field} is not valid`;
    },

    exceptionValidationMessage(
      exception: EditorException,
      editor: DecisionEditor,
    ): string | undefined {
      if (!Object.prototype.hasOwnProperty.call(exception, 'validation')) {
        return;
      }

      let field = '';
      const isAddress = (editor.path.split('.').pop() as string)
        .toLowerCase()
        .includes('address');
      if (isAddress) {
        const id = exception.id;
        field = getExceptionFieldNameById(id);
      } else {
        const path = editor.path.split('.');
        field = objPathToTitle(path[path.length - 1]) || 'Field';
      }

      return getExceptionMessage(field, exception);
    },
  },
});

export default DecisionEdit;
