import {
  ComponentMap,
  AustracCustNumber,
  AustracCustNumberModel,
} from '@/editor-map/types';
import { objPathToTitle } from '@/editor-map/title';

const editorType = 'austrac.ifti-dra.CustNumber';
const title = objPathToTitle;

export function toFormSchema(
  data: AustracCustNumber,
  readonly = false,
  // eslint-disable-next-line @typescript-eslint/ban-types
): object {
  return {
    elements: {
      custNo: {
        label: 'Customer Number',
        type: 'text',
        maxLength: 35,
        required: true,
        placeholder: 'Empty value',
        readonly,
      },
    },
  };
}

export function fromFormModel(
  formModel: AustracCustNumberModel,
): AustracCustNumber {
  return formModel.custNo;
}

export function toFormModel(data: AustracCustNumber): AustracCustNumberModel {
  return { custNo: data };
}

export const componentMap: ComponentMap = {
  [editorType]: {
    title,
    toFormModel,
    fromFormModel,
    toFormSchema,
  },
};

export default componentMap;
