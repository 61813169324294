import austracABN from './austrac.ifti-dra.ABN';
import austracACN from './austrac.ifti-dra.ACN';
import austracAddress from './austrac.ifti-dra.Address';
import austracARBN from './austrac.ifti-dra.ARBN';
import austracAUSTRACDate from './austrac.ifti-dra.AUSTRACDate';
import austracBranchOptCountry from './austrac.ifti-dra.BranchOptCountry';
import austracBusinessStructure from './austrac.ifti-dra.BusinessStructure';
import austracCountry from './austrac.ifti-dra.Country';
import austracCurrencyCode from './austrac.ifti-dra.CurrencyCode';
import austracCustNumber from './austrac.ifti-dra.CustNumber';
import austracDateOfBirth from './austrac.ifti-dra.DateOfBirth';
import austracElectronicDataSource from './austrac.ifti-dra.ElectronicDataSource';
import austracEmail from './austrac.ifti-dra.Email';
import austracIdentificationNoCountry from './austrac.ifti-dra.IdentificationNoCountry';
import austracIftiDraInitiatingInstn from './austrac.ifti-dra.initiatingInstn';
import austracName from './austrac.ifti-dra.Name';
import austracPhoneNum from './austrac.ifti-dra.PhoneNum';
import austracYesNo from './austrac.ifti-dra.YesNo';

export const editorMap = {
  ...austracABN,
  ...austracACN,
  ...austracAddress,
  ...austracARBN,
  ...austracAUSTRACDate,
  ...austracBranchOptCountry,
  ...austracBusinessStructure,
  ...austracCountry,
  ...austracCurrencyCode,
  ...austracCustNumber,
  ...austracDateOfBirth,
  ...austracElectronicDataSource,
  ...austracEmail,
  ...austracIdentificationNoCountry,
  ...austracIftiDraInitiatingInstn,
  ...austracName,
  ...austracPhoneNum,
  ...austracYesNo,
};
