import {
  ComponentMap,
  TransactionDirection,
  TransactionDirectionModel,
} from './types';
import { objPathToTitle } from './title';

const editorType = 'transaction.direction';
const title = objPathToTitle;

// eslint-disable-next-line @typescript-eslint/ban-types
export function toFormSchema(data: string, readonly = false): object {
  return {
    elements: {
      transactionDirection: {
        type: 'select',
        label: 'Transaction Direction',
        options: {
          I: { label: 'I - Into Australia' },
          O: { label: 'O - Out of Australia' },
        },
        readonly,
      },
    },
  };
}

export function fromFormModel(
  formModel: TransactionDirectionModel,
): TransactionDirection {
  return formModel.transactionDirection;
}

export function toFormModel(data: string): TransactionDirectionModel {
  // users shouldn't be able to edit id fields
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const omitIdProps = true;
  return {
    transactionDirection: data as TransactionDirection,
  };
}

const componentMap: ComponentMap = {
  [editorType]: {
    title,
    toFormModel,
    fromFormModel,
    toFormSchema,
  },
};

export { componentMap };
export default componentMap;
