






















































































import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

import { I8Accordion, I8Crumby, I8Icon } from 'i8-ui';

// Icon library
import { faEdit } from '@fortawesome/pro-light-svg-icons/faEdit';

// Add all icons to the library
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faEdit);

import {
  getExceptionFieldNameById,
  getExceptionMessage,
  objPathToTitle,
} from '@/editor-map';

import {
  ReportItemState,
  ReportItemEditApprove,
  ExceptionIgnoreApprove,
  NotReportableApprove,
  NotReportableDownload,
} from '@/store';
import { Decision } from '@/service';
import {
  DECISION_EDIT,
  DECISION_EXCEPTION_IGNORE,
  DECISION_NOT_REPORTABLE,
  DECISION_ORIGINAL_EDIT,
} from './consts';

import { DecisionEdit } from './decision-edit.vue';
import { DecisionOriginalEdit } from './decision-original-edit.vue';
import { DecisionExceptionIgnore } from './decision-exception-ignore.vue';
import { DecisionNotReportable } from './decision-not-reportable.vue';

import { Editor, EditorException } from '../pr-editor-list/types';

export const PrDecisionList = Vue.extend({
  components: {
    I8Accordion,
    I8Crumby,
    I8Icon,
    DecisionEdit,
    DecisionOriginalEdit,
    DecisionExceptionIgnore,
    DecisionNotReportable,
  },

  props: {
    reportItemId: {
      type: String,
      required: true,
    },
    decisions: {
      type: Array,
      default: () => [],
    },
    listHeading: {
      type: String,
      default: 'Approvals',
    },
    listDescription: {
      type: String,
    },
  },

  data() {
    return {
      isDownloading: false,
      string: {
        decisionTitle: {
          [DECISION_EXCEPTION_IGNORE]: 'Ignore Exception',
          [DECISION_NOT_REPORTABLE]: 'Transaction Not Reportable',
          [DECISION_ORIGINAL_EDIT]: 'Report Entry Edit',
          default: 'Decision',
        } as Record<string, string>,
      },
    };
  },

  computed: {
    ...mapGetters('reportItem', ['reportItemDetailsByReportItemId']),

    reportItem(): ReportItemState {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;
      return vm.reportItemDetailsByReportItemId(vm.reportItemId);
    },

    /**
     * List of open approvals/decisions
     *
     * JSON values come as strings form the server so here we're parsing them.
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    decisionList(): any {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return this.decisions.map((d: any) => {
        // eslint-disable-next-line no-prototype-builtins
        if (d.hasOwnProperty('json_value_old')) {
          d.json_value_old = JSON.parse(d.json_value_old);
          d.json_value_new = JSON.parse(d.json_value_new);
        }
        return d;
      });
    },
  },

  methods: {
    ...mapActions('reportItem', [
      'reportItemEditApprove',
      'reportEntryEditApprove',
      'exceptionIgnoreApprove',
      'notReportableApprove',
      'notReportableDownload',
    ]),

    /**
     * Approve a request to edit a transaction.
     *
     * There is no confirmation for this, the approval will be direcly sent to
     * the server.
     */
    approve(decision: Decision) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;

      switch (decision.type) {
        case DECISION_EDIT:
          vm.reportItemEditApprove({
            reportItemId: this.reportItemId,
            editId: decision.id,
          } as ReportItemEditApprove);
          break;

        case DECISION_ORIGINAL_EDIT:
          vm.reportEntryEditApprove({
            reportItemId: this.reportItemId,
            editId: decision.id,
          } as ReportItemEditApprove);
          break;

        case DECISION_EXCEPTION_IGNORE:
          vm.exceptionIgnoreApprove({
            reportItemId: this.reportItemId,
            exceptionId: decision[DECISION_EXCEPTION_IGNORE].exception.id,
          } as ExceptionIgnoreApprove);
          break;

        case DECISION_NOT_REPORTABLE:
          vm.notReportableApprove({
            reportItemId: this.reportItemId,
            editId: decision.id,
          } as NotReportableApprove);
          break;

        default:
          console.warn('unable to approve unknown decision type', decision);
          break;
      }
    },

    /**
     * Reject a request to edit a trasaction.
     *
     * This will re-direct the user to a confirmation screen where they must
     * add a reason for the rejection.
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    reject(decision: any) {
      this.$router.push({
        name: 'report-item.edit.reject',
        params: { decision },
      });
    },

    exceptionMessage(exception: EditorException, editor: Editor): string {
      const message = exception.message;
      if (message) {
        return message;
      }

      let validationMessage: string | undefined;
      if (Object.prototype.hasOwnProperty.call(exception, 'validation')) {
        validationMessage = this.exceptionValidationMessage(exception, editor);
      }

      if (validationMessage) {
        return validationMessage;
      }

      const path = editor.path.split('.');
      const field = objPathToTitle(path[path.length - 1]) || 'Field';
      return `${field} is not valid`;
    },

    exceptionValidationMessage(
      exception: EditorException,
      editor: Editor,
    ): string | undefined {
      if (!Object.prototype.hasOwnProperty.call(exception, 'validation')) {
        return;
      }

      let field = '';
      if (editor.grouped) {
        field = objPathToTitle(exception.id.split('_').pop() || '');
      } else if (editor.type === 'unknown' || exception.id) {
        const id = exception.id;
        field = getExceptionFieldNameById(id);
      } else {
        // Default to path if no id is available, and 'Field' as ultimate fallback
        const path = editor.path.split('.');
        field = objPathToTitle(path[path.length - 1]) || 'Field';
      }

      return getExceptionMessage(field, exception);
    },

    getPath(editor: Editor) {
      // Root-level exceptions have no path, fallback to editor type
      if (editor.type) {
      return editor.path || (editor.type.split('.').pop() as string);
      }
      // editor type does not exist on all editors 
      return editor.path || "" 
    },

    /**
     * Download supporting documentation for a decision
     */
    async downloadJustification(decision: Decision) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;

      switch (decision.type) {
        case DECISION_NOT_REPORTABLE:
          this.isDownloading = true;
          try {
            await vm.notReportableDownload({
              reportItemId: this.reportItemId,
            } as NotReportableDownload);
          } catch (error) {
            console.error(error);
          } finally {
            this.isDownloading = false;
          }
          break;

        default:
          // eslint-disable-next-line no-case-declarations
          const msg = 'unable to download file for unknown decision type';
          console.warn(msg, decision);
          break;
      }
    },

    decisionTitle(decision: Decision): string {
      switch (decision.type) {
        case DECISION_EDIT:
          return this.exceptionMessage(
            decision[decision.type].exceptions[0] as EditorException,
            decision[decision.type] as Editor,
          );
        case DECISION_EXCEPTION_IGNORE:
          // Override title for special cases
          if (
            decision[decision.type].exception.id === 'reportable-with-exception'
          ) {
            return 'Transaction reportable';
          }

          // Ignore: Message
          if (decision[decision.type].exception.message) {
            return `${this.string.decisionTitle[decision.type]}: ${
              decision[decision.type].exception.message
            }`;
          }

          // Default ignore exception title
          return this.string.decisionTitle[decision.type];
        case DECISION_ORIGINAL_EDIT:
        case DECISION_NOT_REPORTABLE:
          return this.string.decisionTitle[decision.type];
        default:
          return this.string.decisionTitle.default;
      }
    },
  },
});
export default PrDecisionList;
