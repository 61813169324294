import {
  ComponentMap,
  TransactionDesignatedServiceModel,
  TransactionDesignatedService,
} from './types';
import { objPathToTitle } from './title';

const editorType = 'austrac.transaction.designatedSvc';
const title = objPathToTitle;

// eslint-disable-next-line @typescript-eslint/ban-types
export function toFormSchema(data: string, readonly = false): object {
  return {
    elements: {
      designatedService: {
        type: 'select',
        label: 'Designated service',
        options: {
          ACC_DEP: { label: 'ACC_DEP - Accounting/deposit taking services' },
          BULSER: { label: 'BULSER - Bullion dealing services' },
          BUS_LOAN: { label: 'BUS_LOAN - Loan services' },
          BUS_RSA: { label: 'BUS_RSA - Retirement savings accounts (RSA)' },
          CHQACCSS: { label: 'CHQACCSS - Chequebook access facilities' },
          CRDACCSS: { label: 'CRDACCSS - Debit card access facilities' },
          CUR_EXCH: { label: 'CUR_EXCH - Currency exchange facilities' },
          CUST_DEP: { label: 'CUST_DEP - Custodial/depository services' },
          DCE: { label: 'DCE - Digital currency exchange services' },
          DEBTINST: { label: 'DEBTINST - Debt instruments' },
          FIN_EFT: { label: 'FIN_EFT - Electronic funds transfers (EFT)' },
          LEASING: { label: 'LEASING - Lease/hire purchase services' },
          LIFE_INS: { label: 'LIFE_INS - Life insurance services' },
          PAYORDRS: { label: 'PAYORDRS - Money/postal orders' },
          PAYROLL: { label: 'PAYROLL - Payroll services' },
          PENSIONS: { label: 'PENSIONS - Pensions/annuity services' },
          RS: { label: 'RS - Remittance services (money transfers)' },
          SECURITY: {
            label: 'SECURITY - Securities market/investment services',
          },
          SUPERANN: {
            label: 'SUPERANN - Superannuation/approved deposit funds',
          },
          TRAVLCHQ: {
            label: "TRAVLCHQ - Traveller's cheque exchange services",
          },
          VALCARDS: { label: 'VALCARDS - Stored value cards' },
        },
        readonly,
      },
    },
  };
}

export function fromFormModel(
  formModel: TransactionDesignatedServiceModel,
): TransactionDesignatedService {
  return formModel.designatedService;
}

export function toFormModel(data: string): TransactionDesignatedServiceModel {
  // users shouldn't be able to edit id fields

  return {
    designatedService: data as TransactionDesignatedService,
  };
}

const componentMap: ComponentMap = {
  [editorType]: {
    title,
    toFormModel,
    fromFormModel,
    toFormSchema,
  },
};

export { componentMap };
export default componentMap;
