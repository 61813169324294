


























































































































// vue
import Vue from 'vue';

import { PrPage } from '@/mixin';
import {
  I8Accordion,
  I8Crumby,
  I8Icon,
  I8Form,
  FormFieldValidation,
  type FormModel,
} from 'i8-ui';

import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import { PrException } from '@/component/pr-exception';

import {
  editorMap,
  fromFormModel,
  getExceptionFieldNameById,
  getExceptionMessage,
  importJsonData,
  objPathToTitle,
  toEditor,
} from '@/editor-map';
import { Editor, EditorAccordion, EditorException } from './types';

// Icon library
import { faCrosshairs } from '@fortawesome/pro-light-svg-icons/faCrosshairs';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle';
// Add all icons to the library
import { library } from '@fortawesome/fontawesome-svg-core';
import { mapGetters } from 'vuex';
library.add(faCrosshairs, faExclamationTriangle);

const ONYX = 'onyx';

export const PrEditorList = Vue.extend({
  mixins: [PrPage],

  components: {
    I8Crumby,
    I8Icon,
    I8Form,
    I8Accordion,
    PrException,
  },

  props: {
    editors: {
      type: Array,
      required: true,
    },
    reportItemId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      pageTitle: 'Data Exceptions',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      editorForm: {} as Record<string, any>,
      formValidation: {} as FormFieldValidation,
      isModified: {} as Record<string, boolean>,
    };
  },

  computed: {
    ...mapGetters('config', ['envName']),
  },

  methods: {
    async validate(isValid: FormModel) {
      this.formValidation = isValid;
      this.checkModified();
    },

    async checkModified() {
      const isModified: Record<string, boolean> = {};

      for (const editor of this.editors as Editor[]) {
        const editorForm = this.editorForm[this.getPath(editor)];
        const original = editor.json_value ? JSON.parse(editor.json_value) : {};

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const isOnyx = (this as any).envName === ONYX;
        let modified;
        if (isOnyx) {
          modified = editorForm.fromFormModel(editorForm.formModel, original);
        } else {
          modified = await editorForm.fromFormModel(
            editor,
            editorForm.formModel,
            original,
          );
        }

        // an editor can output an object with a different shape if there is missing data
        const bothEmpty =
          this.isEmptyDeep(original) && this.isEmptyDeep(modified);

        isModified[editor.path] = !isEqual(original, modified) && !bothEmpty;
      }
      this.isModified = { ...isModified };
    },

    async submit(editor: Editor): Promise<void> {
      this.$root.$emit('i8-form.validate');
      if (!this.formValidation.isValid) {
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const isOnyx = (this as any).envName === ONYX;

      const editorForm = this.editorForm[this.getPath(editor)];
      const oldVal = editor.json_value ? JSON.parse(editor.json_value) : '';

      let newVal;

      // oldVal is used to merge in anything that was removed for editing
      // by default, id props are omitted
      if (isOnyx) {
        // TODO: to be migrated to SaaS editors at a later stage
        newVal = editorForm.fromFormModel(editorForm.formModel, oldVal);
      } else {
        newVal = await editorForm.fromFormModel(
          editor,
          editorForm.formModel,
          oldVal,
        );
      }

      this.$router.push({
        name: 'report-item.edit.create',
        params: {
          path: this.getPath(editor),
          type: editor.type,
          rule_set_id: editor.rule_set_id,
          rule_set_name: editor.rule_set_name || '',
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          exceptions: editor.exceptions as any,
          reportItemId: this.reportItemId,
          oldVal,
          newVal,
        },
      });
    },

    // revert user changes
    cancel(editor: Editor): void {
      this.setEditorForm(editor);
      this.closeEditorForm();
    },

    setEmptyEditorForm(editor: Editor) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const isOnyx = (this as any).envName === ONYX;
      if (isOnyx) return;

      // SaaS
      this.$set(this.editorForm, this.getPath(editor), {
        title: '',
        formModel: null,
        fromFormModel: () => {
          return {};
        },
        formSchema: { elements: {} },
      });
    },

    async setEditorForm(editor: Editor) {
      const editableData = editor.json_value
        ? JSON.parse(editor.json_value)
        : '';

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const isOnyx = (this as any).envName === ONYX;

      if (isOnyx) {
        // TODO: to be migrated to SaaS editors at a later stage
        const map = editorMap[editor.type] || editorMap.unknown;
        this.$set(this.editorForm, editor.path, {
          title: map.title(editor.path),
          formModel: map.toFormModel(editableData),
          fromFormModel: map.fromFormModel,
          formSchema: map.toFormSchema(editableData, editor.read_only),
        });
      } else {
        // SaaS
        const newEditor = await toEditor(editor);

        this.$set(this.editorForm, this.getPath(editor), {
          title: newEditor.title,
          formModel: newEditor.formModel,
          fromFormModel: await fromFormModel,
          formSchema: newEditor.formSchema,
        });
      }
    },

    closeEditorForm() {
      const editorForm = this.$refs.exceptionsAccordion as EditorAccordion;
      editorForm.toggle();
    },

    ignoreException(
      editor: Editor,
      exception: { id: string; message: string },
    ) {
      this.$router.push({
        name: 'report-item.exception.ignore',
        params: {
          reportItemId: this.reportItemId,
          exceptionId: exception.id,
          exceptionMessage: exception.message,
        },
      });
    },

    getPath(editor: Editor) {
      // Root-level exceptions have no path, fallback to editor type
      return editor.path || (editor.type.split('.').pop() as string);
    },

    exceptionMessage(exception: EditorException, editor: Editor): string {
      const message = exception.message;
      if (message) {
        return message;
      }

      let validationMessage: string | undefined;
      if (Object.prototype.hasOwnProperty.call(exception, 'validation')) {
        validationMessage = this.exceptionValidationMessage(exception, editor);
      }

      if (validationMessage) {
        return validationMessage;
      }

      const path = editor.path.split('.');
      const field = objPathToTitle(path[path.length - 1]) || 'Field';
      return `${field} is not valid`;
    },

    exceptionValidationMessage(
      exception: EditorException,
      editor: Editor,
    ): string | undefined {
      if (!Object.prototype.hasOwnProperty.call(exception, 'validation')) {
        return;
      }

      let field = '';
      if (editor.grouped) {
        field = objPathToTitle(exception.id.split('_').pop() || '');
      } else if (editor.type === 'unknown' || exception.id) {
        const id = exception.id;
        field = getExceptionFieldNameById(id);
      } else {
        // Default to path if no id is available, and 'Field' as ultimate fallback
        const path = editor.path.split('.');
        field = objPathToTitle(path[path.length - 1]) || 'Field';
      }

      return getExceptionMessage(field, exception);
    },
    isEmptyDeep(data: Record<string, unknown>): boolean {
      if (isEmpty(data)) {
        return true;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const flattenObject = (obj: Record<string, any>, prefix = '') =>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Object.keys(obj).reduce((acc: Record<string, any>, k) => {
          const pre = prefix.length ? prefix + '.' : '';
          if (typeof obj[k] === 'object') {
            Object.assign(acc, flattenObject(obj[k], pre + k));
          } else {
            acc[pre + k] = obj[k];
          }
          return acc;
        }, {});

      const flattened = flattenObject(data);

      // return true if all values are empty
      return Object.values(flattened).every((x) => !x);
    },
  },

  watch: {
    editors: {
      handler(newEditors: Editor[]) {
        // to avoid undefined issue before json get imported
        for (const editor of newEditors) {
          this.setEmptyEditorForm(editor);
          importJsonData(editor).then(() => {
            this.setEditorForm(editor);
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
});

export default PrEditorList;
