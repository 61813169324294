import {
  ComponentMap,
  AustracACN,
  AustracACNFormModel,
} from '@/editor-map/types';
import { objPathToTitle } from '@/editor-map/title';

const editorType = 'austrac.ifti-dra.ACN';
const title = objPathToTitle;

// eslint-disable-next-line @typescript-eslint/ban-types
export function toFormSchema(data: AustracACN, readonly = false): object {
  return {
    elements: {
      acn: {
        type: 'number',
        maxlength: 9,
        minlength: 9,
        pattern: '[0-9]{9}',
        label: 'ACN',
        required: true,
        placeholder: 'Empty value',
        readonly,
      },
    },
  };
}

export function fromFormModel(formModel: AustracACNFormModel): string {
  return formModel.acn.toString();
}

export function toFormModel(data: AustracACN): AustracACNFormModel {
  return { acn: data };
}

export const componentMap: ComponentMap = {
  [editorType]: {
    title,
    toFormModel,
    fromFormModel,
    toFormSchema,
  },
};

export default componentMap;
