










































import Vue from 'vue';

import { I8Icon } from 'i8-ui';
import { I8Quote } from '@/component/i8-quote';

import { DECISION_NOT_REPORTABLE } from './consts';

// Icon library
import { faSpinner } from '@fortawesome/pro-light-svg-icons/faSpinner';
// Add all icons to the library
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faSpinner);

export const DecisionNotReportable = Vue.extend({
  name: 'decision-not-reportable',

  components: {
    I8Icon,
    I8Quote,
  },

  props: {
    decision: {
      type: Object,
      required: true,
    },
    isDownloading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      decicionType: DECISION_NOT_REPORTABLE,
    };
  },

  computed: {
    fileName() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (this.decision as any)[DECISION_NOT_REPORTABLE]
        .justification_filename;
    },
  },

  methods: {
    downloadFile() {
      this.$emit('downloadJustification');
    },
  },
});

export default DecisionNotReportable;
