






export const I8Quote = {
  name: 'i8-quote',

  props: {
    text: {
      type: String,
      default: '',
    },
  },
};
export default I8Quote;
