export const DECISION_EDIT = 'decision-edit';
export const DECISION_ORIGINAL_EDIT = 'decision-original-edit';
export const DECISION_EXCEPTION_IGNORE = 'decision-exception-ignore';
export const DECISION_NOT_REPORTABLE = 'decision-not-reportable';

export const DECISION_TYPES = [
  DECISION_EDIT,
  DECISION_EXCEPTION_IGNORE,
  DECISION_NOT_REPORTABLE,
];
