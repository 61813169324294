import {
  ComponentMap,
  AustracABN,
  AustracABNFormModel,
} from '@/editor-map/types';
import { objPathToTitle } from '@/editor-map/title';

const editorType = 'austrac.ifti-dra.ABN';
const title = objPathToTitle;

// eslint-disable-next-line @typescript-eslint/ban-types
export function toFormSchema(data: AustracABN, readonly = false): object {
  return {
    elements: {
      abn: {
        type: 'text',
        minlength: 11,
        maxlength: 11,
        pattern: '[0-9]{11}',
        label: 'ABN',
        required: true,
        placeholder: 'Empty value',
        readonly,
      },
    },
  };
}

export function fromFormModel(formModel: AustracABNFormModel): string {
  // the value in the report is a string.
  return formModel.abn.toString();
}

export function toFormModel(data: AustracABN): AustracABNFormModel {
  return { abn: data };
}

export const componentMap: ComponentMap = {
  [editorType]: {
    title,
    toFormModel,
    fromFormModel,
    toFormSchema,
  },
};

export default componentMap;
