import {
  FormModel,
  fromFormModel as i8FromFormModel,
  I8FormElement,
  I8FormElementGroup,
  I8FormElementOption,
  I8FormElementOptions,
  I8FormElementSelect,
  I8FormSchema,
  toFormModel as i8ToFormModel,
  toFormSchema as i8ToFormSchema,
} from 'i8-ui';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import lowerFirst from 'lodash/lowerFirst';
import trim from 'lodash/trim';

import { Editor, EditorException } from '@/component/pr-editor-list/types';
import { objPathToTitle } from '@/editor-map/title';
import { getCountryCodes, getCountryNames, getCurrencies } from '@/util';

import {
  EditorElementSelect,
  EditorOptionType,
  EditorRegulator,
  GoAmlRegulators,
  I8FormInputType,
  HasCustomMappingData,
  JsonDataMapping,
  RulePathTypeMapping,
  RuleType,
} from './types';

import {
  I8FormElementData,
  I8FormElementGroupData,
  I8FormSchemaData,
} from './types';

const I8_CHOICE = 'i8_choice';
let jsonDataMapping: JsonDataMapping = {};

function isFormElementGroup(
  element: I8FormElement | I8FormElementGroup,
): element is I8FormElementGroup {
  return (
    isObject(element) && Object.prototype.hasOwnProperty.call(element, 'schema')
  );
}

//#region toformModel

/**
 * Transform an i8form schema into a form model object
 * the transformed formModel object contains all elements in formSchema
 * including i8_choice
 * @param formSchema
 */
function getDefaultFormModel(formSchema: I8FormSchema): FormModel {
  if (isEmpty(formSchema)) return {};

  const elements = formSchema.elements;
  return Object.keys(elements).reduce((accumulator, key) => {
    const element = elements[key];
    if (isFormElementGroup(element)) {
      return {
        ...accumulator,
        [key]: getDefaultFormModel(element.schema),
      };
    }
    return { ...accumulator, [key]: '' };
  }, {});
}

/**
 * Init choice and array data
 * @param formSchema
 * @param data
 * @returns
 */
export function toFormModelInit(
  formSchema: I8FormSchema,
  data: FormModel,
): FormModel {
  const elements = formSchema.elements;
  const result = cloneDeep(data);
  Object.keys(elements).forEach((key) => {
    const element = elements[key] as I8FormElementGroupData | I8FormElementData;

    // Initialise I8 choice options if data exists for that choice
    if (key.includes(I8_CHOICE)) {
      const dataKeys = Object.keys(data);
      const options = (element as I8FormElementSelect)
        .options as I8FormElementOptions;

      let bestChoice: null | number = null;
      let mostFieldsWithValues = 0;

      for (let i = 0; i < Object.keys(options).length; i++) {
        const k = Object.keys(options)[i];
        const fields = getChoiceFields(options[k] as I8FormElementOption);

        let fieldsWithValues = 0;

        // Check for values corresponding to each field in the choice option
        fields.forEach((field) => {
          const hasValue =
            (!!data[field] && dataKeys.includes(field)) ||
            (!!data[`${field}[0]`] && dataKeys.includes(`${field}[0]`));
          // TODO: Support more than one array item  and get rid of these hardcoded [0] indexes

          if (hasValue) {
            fieldsWithValues++;
          }
        });

        // The choice option with the most fields with values is the best choice
        if (fieldsWithValues > mostFieldsWithValues) {
          mostFieldsWithValues = fieldsWithValues;
          bestChoice = i;
        }
      }

      // If we have a candidate for the best choice, set it
      if (bestChoice !== null) {
        result[key] = `choice_${bestChoice}`;
      }

      // If required, default i8_choice to the first choice option
      if (!result[key] && (element as I8FormElement).required) {
        result[key] = 'choice_0';
      }
    } else {
      if (isFormElementGroup(element) && result[key]) {
        const insideData = toFormModelInit(element.schema, result[key]);
        if (insideData) {
          result[key] = insideData;
        }
      }
    }

    // destruct if it is with array data
    if (element.isArray) {
      if (result[key] && Array.isArray(result[key])) {
        // Create a new object with the array items as indexed properties
        result[key].forEach((item: unknown, idx: number) => {
          result[`${key}[${idx}]`] = item;
        });
        // Remove original array
        delete result[key];
      }
    }
  });

  // console.log('🦭 toFormModelInit', result);

  return result;
}

/**
 * Get the fields in a choice option, first by original key(s) if known, fallback to the fields in the label
 * The lowerFirst transform is currently safe with our supported schemas, but may need to be revised later
 * @param options I8FormElementOption
 * @returns array of the field keys in the choice option
 */
function getChoiceFields(options: I8FormElementOption) {
  if (options.originalKeys) {
    return options.originalKeys;
  }
  return options.label.split(',').map((x) => lowerFirst(trim(x)));
}

/**
 * Destructure all arrays in the data as indexed properties
 * @param data
 */
function destructureArrays(
  data: FormModel | string | number,
): FormModel | string | number {
  if (isString(data) || isNumber(data)) return data;

  // eslint-disable-next-line prefer-const
  let result = cloneDeep(data);

  // Destructure if root is array
  if (Array.isArray(data)) {
    // Create a new object with the array items as indexed properties
    data.forEach((item: unknown, idx: number) => {
      result[`[${idx}]`] = item;
    });
  }

  // Recurse into object properties
  if (isObject(result)) {
    Object.keys(result).forEach((key) => {
      const elem = result[key];
      if (Array.isArray(elem)) {
        // Create a new object with the array items as indexed properties
        result[key].forEach((item: unknown, idx: number) => {
          result[`${key}[${idx}]`] = destructureArrays(item as FormModel);
        });
        delete result[key];
      } else {
        result[key] = destructureArrays(result[key]);
      }
    });
  }

  return result;
}

export function toFormModel(
  data: FormModel | string | number,
  formSchema: I8FormSchema,
): FormModel {
  // destruct all array in the original data
  data = destructureArrays(data);

  // when it is an object
  let defaultFormModel = getDefaultFormModel(formSchema);

  defaultFormModel = consolidateModel(data as FormModel, defaultFormModel);

  let model = cloneDeep(defaultFormModel);

  const isSingle = (m: FormModel | string): m is string =>
    Object.keys(m).length === 1;

  // when there is only single value
  if (isSingle(defaultFormModel)) {
    const keyVal = Object.keys(defaultFormModel)[0];
    const val = defaultFormModel[Object.keys(defaultFormModel)[0]];
    if (isNumber(val) || isString(val)) {
      return { [keyVal]: data };
    }
    // object, e.g. mainAddress
    if (isObject(val)) {
      model[keyVal] = { ...model[keyVal], ...(data as FormModel) };
    }
  } else {
    model = { ...defaultFormModel, ...(data as FormModel) };
  }

  // console.log(' 🦐 toFormModel', model['customer[1]']['account[1]']);

  // handle choice and array
  model = toFormModelInit(formSchema, removeEmpty(model) as FormModel);

  // console.log('  🦐 toFormModel', model['customer[1]']['account[1]']);

  // users shouldn't be able to edit id fields
  // omitIdProps set to true
  model = i8ToFormModel(model, true);

  return model;
}

/**
 * Recursively consolidate the form model with the default form model.
 * This will add any missing array item models with their default values
 */
function consolidateModel(model: FormModel, defaultModel: FormModel) {
  Object.keys(model).forEach((key: string) => {
    // If the schema doesn't have an element for the model, add it
    if (defaultModel && !defaultModel[key]) {
      if (key.includes('[')) {
        // Source the default model value from item[0]
        const oKey = key.split('[')[0];
        defaultModel[key] = cloneDeep(defaultModel[`${oKey}[0]`]);
      }

      // Recurse
      if (model[key] instanceof Object) {
        // If the model is an object, we need to recurse
        consolidateModel(model[key] as FormModel, defaultModel[key]);
      }
    }
  });
  return defaultModel as FormModel;
}
//#endregion

/**
 * Recursively consolidate the form schema from the form model, adding and removing elements as needed
 */
export function consolidateSchema(
  model: FormModel,
  schema: I8FormSchema,
): I8FormSchema {
  // For each property in the model, we need to generate a schema
  Object.keys(model).forEach((key: string) => {
    // If the schema doesn't have an element for ther model, add it
    if (!schema.elements[key]) {
      if (key.includes('[')) {
        // Source and clone the schema from item[0]
        const oKeyName = key.split('[')[0];
        const oKey = `${oKeyName}[0]`;
        const oSchema = schema.elements[oKey];

        if (!oSchema) return;

        // TODO: (SL) need to display the schema in a sensible order.
        // Currently it is in the order of the inherited order of the
        // object keys, so new props will be added to the end of the schema
        // (roughly, because it's still an object with no guaranteed order)

        schema.elements[key] = cloneDeep(oSchema);
        // We append a 1 to the original elements label
        schema.elements[oKey].label = objPathToTitle(oKey);
        // Generate a incremented label with numbering based on path[index] (index + 1)
        schema.elements[key].label = objPathToTitle(key);

        // Consolidate groups
        if (isFormElementGroup(schema.elements[key])) {
          let group = schema.elements[key] as I8FormElementGroup;
          const oGroup = schema.elements[oKey] as I8FormElementGroup;
          // We append a 1 to the original group title
          oGroup.title = objPathToTitle(oKey);
          // Generate a nice group title with numbering based on path[index] (index + 1)
          group.title = objPathToTitle(key);
          // Increment the nested jexl expressions
          group = replaceKeysInJexl(group, key, oKey);
          // We need processing for jexl expressions
          schema.needsProcessing = true;
        }
      }
    }

    // Recurse
    if (isFormElementGroup(schema.elements[key])) {
      const group = schema.elements[key] as I8FormElementGroup;
      consolidateSchema(model[key] as FormModel, group.schema);
    }
  });
  return schema as I8FormSchema;
}

/**
 * Replace all keys for destructured arrays in nested jexl expressions for the current index
 * @param element The current element scope within an I8FormSchema
 * @param key New destructured key. Eg: element[2]
 * @param oKey Original destructured key. Eg: element[0]
 * @returns I8FormElementGroup
 */
function replaceKeysInJexl(
  element: I8FormElementGroup,
  key: string,
  oKey: string,
) {
  Object.keys(element.schema.elements).forEach((k: string) => {
    if (element.schema.elements[k].showIf) {
      // If the element contains a showIf condition, replace the key.
      // Currently assumes that original keys are unique as we descend into the schema.
      element.schema.elements[k].showIf = {
        _jexl:
          element.schema.elements[k].showIf?._jexl.replace(oKey, key) || '',
      };
    }

    if (isFormElementGroup(element.schema.elements[k])) {
      // Recurse!
      element.schema.elements[k] = replaceKeysInJexl(
        element.schema.elements[k] as I8FormElementGroup,
        key,
        oKey,
      ) as I8FormElementGroup;
    }
  });
  return element;
}
//#endregion

//#region fromFormModel
/**
 * remove choice from the model
 * @param formModel
 * @param omitIdProps
 */
function tidyFormModel(formModel: FormModel): FormModel {
  const model = clone(formModel);
  Object.keys(model).forEach((key) => {
    const currentModel = model[key];
    if (key.includes(I8_CHOICE)) {
      delete model[key];
    } else if (isObject(currentModel)) {
      model[key] = tidyFormModel(currentModel);
    }
  });
  return model;
}

/**
 * only add keys which are not in formSchema
 */
function tidySourceData(formSchema: I8FormSchemaData, sourceData: FormModel) {
  const data = cloneDeep(sourceData);
  let schemaKeys = Object.keys(formSchema.elements);
  if (schemaKeys.length === 1) {
    const elem = formSchema.elements[schemaKeys[0]];
    if (isFormElementGroup(elem)) {
      schemaKeys = Object.keys(elem.schema.elements);
    }
  }
  Object.keys(sourceData).forEach((key) => {
    if (schemaKeys.includes(key)) {
      delete data[key];
    }
  });
  return data;
}

/**
 * init fromFormModel, convert array data when necessary
 * @param formSchema
 * @param data
 */
function fromFormModelInit(
  formSchema: I8FormSchemaData,
  data: FormModel,
): FormModel {
  const elements = formSchema.elements;
  let result = cloneDeep(data);
  Object.keys(elements).forEach((key) => {
    const element = elements[key] as I8FormElementGroupData | I8FormElementData;
    const schema = (element as I8FormElementGroup)?.schema;

    // Recursive schemas
    if (result[key] && schema) {
      result[key] = fromFormModelInit(schema, result[key]);
    }

    // restruct if it is with array data
    if (element.isArray && result[key] && !Array.isArray(result[key])) {
      result[key] = [result[key]];
    }
  });
  if (formSchema.isArray) {
    result = [result];
  }
  return result;
}

export function removeEmpty(model: FormModel | string): FormModel | string {
  if (!isObject(model)) return model;

  const finalObj: FormModel = {};
  Object.keys(model).forEach((key: string) => {
    const value = (model as FormModel)[key];
    if (value && typeof value === 'object') {
      const nestedObj = removeEmpty(value);
      if (Object.keys(nestedObj).length) {
        finalObj[key] = nestedObj;
      }
    } else if (value !== '' && value !== undefined && value !== null) {
      finalObj[key] = value;
    }
  });
  return finalObj;
}

export async function fromFormModel(
  editor: Editor,
  formModel: FormModel,
  sourceData: FormModel | string = {},
): Promise<FormModel | string> {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const formSchema = await editorToSchema(editor);

  formModel = tidyFormModel(formModel);
  if (isObject(sourceData)) {
    // only take id back for now
    // if exist in schema but not in model, delete
    sourceData = tidySourceData(formSchema, sourceData);
  }

  // if with single element in schema
  // only return element content as model
  // e.g. with single group/text element
  const keys = Object.keys(formSchema.elements);
  if (keys.length === 1) {
    const key = keys[0];
    formModel = removeEmpty(formModel[key]) as FormModel;
    // Return non-object model values before further processing
    if (!isObject(formModel)) return formModel;
  }

  // We can't use sourceData as defaults (as commented out above), as it may contain data that needs to be ommitted from the value
  // Eg: a choice scenario where 2 values are presentin sourceData, but only one is valid for submission
  const fromFormModel = tidyForSubmission(formModel);

  // Prepare resulting data
  const result = fromFormModelInit(formSchema, fromFormModel as FormModel);
  return result;
}
//#endregion

// Removes empty properties and restructures arrays
function tidyForSubmission(data: FormModel) {
  const cleanData = removeEmpty(data) as FormModel;
  return i8FromFormModel(cleanData);
}

//#region formSchema
function getOptions(
  options: I8FormElementOptions,
  regulator: string,
  fileRuleType: string,
  typeName: string,
): I8FormElementOptions {
  const optionData = jsonDataMapping[`${regulator}.${fileRuleType}`].option;

  const optionMapping = get(optionData, typeName);
  if (!optionMapping) return options;

  return Object.keys(optionMapping).reduce((accumulator, currentValue) => {
    const val = optionMapping[currentValue];

    // Format the option label if the mapped display value is different to the current value
    const label =
      val != currentValue ? `${currentValue} - ${val}` : currentValue;

    return {
      ...accumulator,
      [currentValue]: { label },
    };
  }, {});
}

async function replaceOptions(
  element: I8FormElement,
  regulator: string,
  fileRuleType: string,
): Promise<I8FormElement> {
  const isSelect = (el: I8FormElement): el is EditorElementSelect =>
    el.type === I8FormInputType.SELECT;

  if (!isSelect(element)) {
    return element;
  }

  // replace options if type is select
  const optionType = element.optionType as string;
  delete element.optionType;

  let options = element.options as I8FormElementOptions;
  switch (optionType) {
    case EditorOptionType.COUNTRY:
      options = await getCountryNames();
      break;
    case EditorOptionType.COUNTRY_CODE:
      options = await getCountryCodes();
      break;
    case EditorOptionType.CURRENCY_CODE:
      options = await getCurrencies();
      break;
    default:
      options = getOptions(options, regulator, fileRuleType, optionType);
  }

  return { ...element, options } as I8FormElement;
}

/**
 * Returns a form schema ready to be used
 *
 * Add in select options
 *
 * @param formSchema
 * @param regulator
 * @param fileRuleType
 * @param readonly
 * @returns I8FormSchema
 */
async function updateSchema(
  formSchema: I8FormSchema,
  regulator: string,
  fileRuleType: string,
  readonly = false,
): Promise<I8FormSchema> {
  if (isEmpty(formSchema)) {
    return formSchema;
  }

  const elements = formSchema.elements;

  Object.keys(elements).forEach(async (key) => {
    const element = elements[key];

    if (isFormElementGroup(element)) {
      // Some groups can be designated as hidden; meaning they do not need to be rendered or modified,
      // but their associated model will still be included for submission
      const isHidden = !!element.hidden;

      // look for options in the group
      await updateSchema(element.schema, regulator, fileRuleType, isHidden);
    } else {
      // add in the select options
      elements[key] = await replaceOptions(element, regulator, fileRuleType);

      if (readonly) {
        elements[key] = { ...elements[key], readonly } as I8FormElement;
      }
    }
  });

  return formSchema;
}

function getMappingPath(
  path: string,
  regulator: string,
  ruleType: string,
): string {
  // Austrac, e.g. change iftie-swift to swift
  if (
    ruleType == RuleType.AUSTRAC_IFTIE_SWIFT ||
    ruleType == RuleType.AUSTRAC_IFTIE_STRUCTURED ||
    ruleType == RuleType.AUSTRAC_IFTIE_ISO20022 // iso's prefix is iso20022
  ) {
    // Embedded AppHdr, Pacs8 and Pacs9 documents are not prefixed
    if (
      !path.startsWith('AppHdr') &&
      !path.startsWith('Pacs8Document') &&
      !path.startsWith('Pacs9Document')
    ) {
      const prefix = ruleType.replace('iftie-', '');
      path = `${prefix}.${path}`;
    }
  }

  // GoAML Regulators
  if (GoAmlRegulators.includes(regulator as EditorRegulator)) {
    // Our editor mappings are generated from the root of the XSD, but the path (currently) returned via api starts under 'report.transaction'. So we safely prepend that prefix here to match the mapping.
    const prefix = 'report.transaction';
    if (!path.startsWith(prefix)) {
      path = `${prefix}.${path}`;
    }
  }

  return path;
}

function getRuleType(type: string) {
  if (
    type.startsWith(EditorRegulator.AUSTRAC) ||
    type.startsWith(EditorRegulator.NZ_FIU)
  ) {
    return { regulator: type.split('.')[0], ruleType: type.split('.')[1] };
  }
  return { regulator: '', ruleType: type };
}

function getRegulatorRuleTypeFromExceptions(
  exceptions: EditorException[],
  type: string,
) {
  const ids = exceptions.map((x) => x.id);
  let regulator = '';
  let id = '';
  let ruleType = '';

  if (ids.find((x) => x.startsWith(EditorRegulator.AUSTRAC))) {
    regulator = EditorRegulator.AUSTRAC;
    id = ids.find((x) => x.startsWith(EditorRegulator.AUSTRAC)) as string;
  } else if (ids.find((x) => x.startsWith(EditorRegulator.NZ_FIU))) {
    regulator = EditorRegulator.NZ_FIU;
    ruleType = RuleType.NZ_FIU_PTR;
    return { regulator, ruleType };
  } else {
    return getRuleType(type);
  }

  // assuming the format is like austrac_xxx_xxx_v1.2_xxx
  const matchedSeperator = id.match(/_v\d*.?\d*_/);
  if (matchedSeperator) {
    const separator = matchedSeperator[0];
    const ruleArr = id.split(separator)[0].split('_');
    ruleArr.splice(0, 1);
    ruleType = ruleArr.join('-');
    return {
      regulator,
      ruleType,
    };
  }

  // won't reach here if the format is correct
  return getRuleType(type);
}

function getJsonFileRuleType(regulator: string, ruleType: string) {
  if (regulator != EditorRegulator.AUSTRAC) return ruleType;

  // iso20022 is using another xsd mapping, not the ifti-e one, not need to change ruleType for it
  if (
    ruleType == RuleType.AUSTRAC_IFTIE_SWIFT ||
    ruleType == RuleType.AUSTRAC_IFTIE_STRUCTURED
  ) {
    ruleType = RuleType.AUSTRAC_IFTI_E;
  }

  return ruleType;
}

function getRegulatorRuleTypeFromEditor(editor: Editor) {
  const { exceptions, type } = editor;
  const { regulator, ruleType } = getRegulatorRuleTypeFromExceptions(
    exceptions,
    type,
  );
  const fileRuleType = getJsonFileRuleType(regulator, ruleType);
  return { regulator, ruleType, fileRuleType };
}

export async function toFormSchema(
  path: string,
  regulator: string,
  ruleType: string,
  fileRuleType: string,
  readonly: boolean,
  data: FormModel | string,
  isArrayItem: boolean,
  editorType?: string,
): Promise<I8FormSchema> {
  // Root level exceptions have no path; default to editor type
  if (path == '' && editorType) {
    path = editorType.split('.').pop() || '';
  }

  // Transform type and path to something we can use in the mapping data
  const newPath = getMappingPath(path, regulator, ruleType);

  const mappingData: RulePathTypeMapping =
    jsonDataMapping[`${regulator}.${fileRuleType}`]?.mapping;

  if (!mappingData) {
    return defaultFormSchema(readonly, data);
  }

  // Get our form schema from the generated mappings
  const formSchema: I8FormSchema = mappingData[newPath];

  if (formSchema) {
    // return the formSchema with all the correct form options
    const schema = await updateSchema(
      formSchema,
      regulator,
      fileRuleType,
      readonly,
    );
    // single array element, not array, e.g. payer[0]
    if (isArrayItem && (schema as I8FormSchemaData).isArray) {
      (schema as I8FormSchemaData).isArray = false;
    }
    return schema;
  }
  return defaultFormSchema(readonly, data);
}

function defaultFormSchema(readonly: boolean, data: FormModel | string) {
  // generate a form schema if we don't have one pre-defined
  return i8ToFormSchema(data as Record<string, unknown>, {
    omitIdProps: true,
    readonly,
    placeholder: 'Empty value',
  }) as I8FormSchema;
}
//#endregion

async function editorToSchema(editor: Editor) {
  const { json_value, path, read_only: readonly = false } = editor;

  const data = json_value ? JSON.parse(json_value) : '';

  // check if the path is array element
  const isArrayItem = /\[\d+\]$/.test(path);
  const actualPath = path.replace(/\[\d+\]/g, '');

  const { regulator, ruleType, fileRuleType } =
    getRegulatorRuleTypeFromEditor(editor);

  return await toFormSchema(
    actualPath,
    regulator,
    ruleType,
    fileRuleType,
    readonly,
    data,
    isArrayItem,
    editor?.type,
  );
}

export async function toEditor(editor: Editor): Promise<{
  title: string;
  formSchema: I8FormSchema;
  formModel: FormModel;
}> {
  const { json_value, path } = editor;
  const data = json_value ? JSON.parse(json_value) : '';

  // Initial schema and model
  let formSchema = await editorToSchema(editor);
  let formModel = toFormModel(data, formSchema);

  // Consolidated schema and model
  formSchema = consolidateSchema(formModel, formSchema);
  formModel = toFormModel(data, formSchema);

  const customTitle = formSchema?.custom?.title as string;
  const title = customTitle ?? objPathToTitle(path, true);

  return {
    title,
    formSchema,
    formModel,
  };
}

export function getExceptionFieldNameById(id: string): string {
  const matchedSeperator = id.match(/_v\d*.?\d*_/);
  if (matchedSeperator) {
    const separator = matchedSeperator[0];
    return objPathToTitle(id.split(separator)[1].split('.').pop() || '');
  }
  return '';
}

export function getExceptionMessage(
  field: string,
  exception: EditorException,
): string {
  let message = 'is not valid';

  const validation = exception.validation;
  switch (validation.type) {
    case 'required':
      message = `is required`;
      break;

    case 'maxLength':
      message = `has a max length of ${validation.value}`;
      break;

    case 'minLength':
      message = `has a min length of ${validation.value}`;
      break;

    case 'enumeration':
      message = `is not one of the allowed values`;
      break;

    case 'length':
      message = `must be exactly ${validation.value} characters`;
      break;

    case 'totalDigits':
      message = `must be exactly ${validation.value} digits`;
      break;

    case 'minDate':
      message = `is earlier than the min date ${validation.value}`;
      break;

    case 'maxDate':
      message = `is later than the max date ${validation.value}`;
      break;

    case 'pattern':
      message = `does not meet the required format`;
      break;

    case 'fixed':
      message = `must equal ${validation.value}`;
      break;

    case 'choice':
      return `Select the type of data that satisfies the field`;
  }

  return `${field} ${message}`;
}

export async function importJsonData(editor: Editor): Promise<void> {
  const { regulator, fileRuleType } = getRegulatorRuleTypeFromEditor(editor);

  const mappingName = `${regulator}.${fileRuleType}`;

  const mappingLoaded = Object.prototype.hasOwnProperty.call(
    jsonDataMapping,
    mappingName,
  );

  if (mappingLoaded) return;

  try {
    // Import generated mappings and options
    let { default: mappingData } = await import(
      `./${regulator}/${mappingName}.mapping.json`
    );
    const { default: optionData } = await import(
      `./${regulator}/${mappingName}.option.json`
    );

    // Import overrides if defined
    // We can override the generated mappings to simplify or improve form UX
    if (HasCustomMappingData.includes(fileRuleType)) {
      const { default: customMappingData } = await import(
        `./${regulator}/${mappingName}.custom.json`
      );
      mappingData = { ...mappingData, ...customMappingData };
    }

    jsonDataMapping = {
      ...jsonDataMapping,
      [mappingName]: {
        mapping: mappingData,
        option: optionData,
      },
    };
  } catch (e) {
    console.error('Unable to get mapping', regulator, fileRuleType, e);
  }
}
