import {
  ComponentMap,
  AustracCurrencyCode,
  AustracCurrencyCodeFormModel,
  I8FormOptions,
} from '@/editor-map/types';
import { objPathToTitle } from '@/editor-map/title';
import { currenciesIso4217 } from '@/util';
const editorType = 'austrac.ifti-dra.CurrencyCode';
const title = objPathToTitle;

export function toFormSchema(
  data: AustracCurrencyCode,
  readonly = false,
  // eslint-disable-next-line @typescript-eslint/ban-types
): object {
  const options = currenciesIso4217.reduce((options, c) => {
    options[c.code] = { label: `${c.code} - ${c.currency}` };
    return options;
  }, {} as I8FormOptions);

  return {
    elements: {
      currency: {
        type: 'select',
        label: 'Currency',
        options,
        readonly,
      },
    },
  };
}

export function fromFormModel(
  formModel: AustracCurrencyCodeFormModel,
): AustracCurrencyCode {
  return formModel.currency;
}

export function toFormModel(
  data: AustracCurrencyCode,
): AustracCurrencyCodeFormModel {
  return { currency: data };
}

export const componentMap: ComponentMap = {
  [editorType]: {
    title,
    toFormModel,
    fromFormModel,
    toFormSchema,
  },
};

export default componentMap;
