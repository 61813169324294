import {
  ComponentMap,
  AustracYesNoFormModel,
  AustracYesNo,
} from '@/editor-map/types';
import { objPathToTitle } from '@/editor-map/title';

const editorType = 'austrac.ifti-dra.YesNo';
const title = objPathToTitle;

// eslint-disable-next-line @typescript-eslint/ban-types
export function toFormSchema(data: string, readonly = false): object {
  return {
    elements: {
      yesNo: {
        type: 'select',
        label: '',
        options: {
          Y: { label: 'Y - Yes' },
          N: { label: 'N - No' },
        },
        readonly,
      },
    },
  };
}

export function fromFormModel(formModel: AustracYesNoFormModel): AustracYesNo {
  return formModel.yesNo;
}

export function toFormModel(data: AustracYesNo): AustracYesNoFormModel {
  return { yesNo: data };
}

const componentMap: ComponentMap = {
  [editorType]: {
    title,
    toFormModel,
    fromFormModel,
    toFormSchema,
  },
};

export { componentMap };
export default componentMap;
