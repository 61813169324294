import { ComponentMap, Party } from './types';
import { objPathToTitle } from './title';
import isEmpty from 'lodash/isEmpty';

const editorType = 'austrac.iftie.addrAcc';
const title = objPathToTitle;

interface FormModel {
  mainAddress: {
    addr: string;
    suburb: string;
    state: string;
    postcode: string;
    country: string;
  };
  account: {
    bsb: string;
    number: string;
  };
}

const emptyParty: FormModel = {
  mainAddress: {
    addr: '',
    suburb: '',
    state: '',
    postcode: '',
    country: '',
  },
  account: {
    bsb: '',
    number: '',
  },
};

export function toFormSchema(
  data: Party,
  readonly = false,
): Record<string, unknown> {
  return {
    elements: {
      mainAddress: {
        type: 'group',
        title: 'Main Address',
        schema: {
          elements: {
            addr: {
              type: 'text',
              label: 'Address',
              placeholder: 'Empty value',
              maxLength: 140,
              readonly,
            },
            suburb: {
              type: 'text',
              label: 'Suburb',
              placeholder: 'Empty value',
              maxLength: 35,
              readonly,
            },
            state: {
              type: 'text',
              label: 'State',
              placeholder: 'Empty value',
              maxLength: 35,
              readonly,
            },
            postcode: {
              type: 'text',
              label: 'Postcode',
              placeholder: 'Empty value',
              maxLength: 15,
              readonly,
            },
            country: {
              type: 'text',
              label: 'Country',
              placeholder: 'Empty value',
              maxLength: 35,
              readonly,
            },
          },
        },
      },
      account: {
        type: 'group',
        title: 'Account',
        schema: {
          elements: {
            bsb: {
              type: 'text',
              placeholder: 'Empty value',
              label: 'BSB',
              pattern: '[0-9]{6}',
              maxLength: 6,
              minLength: 6,
              readonly,
            },
            number: {
              type: 'text',
              label: 'Number',
              placeholder: 'Empty value',
              maxLength: 34,
              readonly,
            },
          },
        },
      },
    },
  };
}

export function fromFormModel(formModel: FormModel): Party {
  return {
    ...formModel,

    // the onyx mappings only support a single account
    account: isEmpty(formModel.account) ? undefined : [formModel.account],
  };
}

export function toFormModel(data: Party): FormModel {
  return {
    ...data, // this will include fields not in the form schema

    // the onyx mappings only support a single account
    account: data.account?.length ? data.account[0] : emptyParty.account,
  } as FormModel;
}

export const componentMap: ComponentMap = {
  [editorType]: {
    title,
    toFormModel,
    fromFormModel,
    toFormSchema,
  },
};

export default componentMap;
