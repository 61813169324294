/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  I8FormElement,
  I8FormElementGroup,
  I8FormElementSelect,
  I8FormSchema,
} from 'i8-ui';

export type RulePathTypeMapping = Record<string, I8FormSchema>;

export const EditorRegulator = {
  AUSTRAC: 'austrac',
  NZ_FIU: 'nz_fiu',
} as const;
export type EditorRegulator =
  (typeof EditorRegulator)[keyof typeof EditorRegulator];

export const GoAmlRegulators = [EditorRegulator.NZ_FIU] as EditorRegulator[];

export const RuleType = {
  AUSTRAC_IFTI_DRA: 'ifti-dra',
  AUSTRAC_IFTIE_SWIFT: 'iftie-swift',
  AUSTRAC_IFTIE_STRUCTURED: 'iftie-structured',
  AUSTRAC_IFTIE_ISO20022: 'iftie-iso20022',
  AUSTRAC_IFTI_E: 'ifti-e',
  AUSTRAC_TTR_FBS: 'ttrfbs',
  AUSTRAC_TTR_MSB: 'ttrmsb',
  NZ_FIU_PTR: 'ptr',
} as const;
export type RuleType = (typeof RuleType)[keyof typeof RuleType];

export const HasCustomMappingData: string[] = [RuleType.AUSTRAC_TTR_MSB];

export interface JsonDataMapping {
  [x: string]: {
    mapping: RulePathTypeMapping;
    option: Record<string, Record<string, string>>;
  };
}

export const I8FormInputType = {
  TEXT: 'text',
  DATE: 'date',
  SELECT: 'select',
  CHECKBOX: 'checkbox',
  CHECKBOX_GROUP: 'checkbox-group',
  NUMBER: 'number',
  RADIO: 'radio',
  TEXTAREA: 'textarea',
} as const;
export type I8FormInputType =
  (typeof I8FormInputType)[keyof typeof I8FormInputType];

export const EditorOptionType = {
  COUNTRY: 'Country',
  COUNTRY_CODE: 'CountryCode',
  CURRENCY_CODE: 'CurrencyCode',
} as const;
export type EditorOptionType =
  (typeof EditorOptionType)[keyof typeof EditorOptionType];

export interface EditorElementSelect extends I8FormElementSelect {
  optionType?: string;
}

export type I8FormElementGroupData = I8FormElementGroup & {
  isArray?: boolean;
};

export type I8FormElementData = I8FormElement & {
  isArray?: boolean;
};

export type I8FormSchemaData = I8FormSchema & {
  isArray?: boolean;
};

export interface ComponentMap {
  [key: string]: {
    title: (path: string) => string;
    toFormModel: any;
    fromFormModel: any;
    toFormSchema: any;
  };
}

export interface I8FormOptions {
  [key: string]: { label: string };
}

export interface IdentificationTypeModel {
  type: IdentificationType;
}

export type IdentificationType =
  | 'A'
  | 'C'
  | 'D'
  | 'P'
  | 'T'
  | 'ARNU'
  | 'CUST'
  | 'BENE'
  | 'BCNO'
  | 'BUSR'
  | 'EMID'
  | 'EMPL'
  | 'IDNT'
  | 'MEMB'
  | 'PHOT'
  | 'SECU';

export interface TransactionDirectionModel {
  transactionDirection: TransactionDirection;
}

export type TransactionDirection = 'I' | 'O';

export interface TransactionDesignatedServiceModel {
  designatedService: TransactionDesignatedService;
}
export type TransactionDesignatedService =
  | 'ACC_DEP'
  | 'BULSER'
  | 'BUS_LOAN'
  | 'BUS_RSA'
  | 'CHQACCSS'
  | 'CRDACCSS'
  | 'CUR_EXCH'
  | 'CUST_DEP'
  | 'DCE'
  | 'DEBTINS'
  | 'FIN_EFT'
  | 'LEASING'
  | 'LIFE_INS'
  | 'PAYORDRS'
  | 'PAYROLL'
  | 'PENSIONS'
  | 'RS'
  | 'SECURITY'
  | 'SUPERAN'
  | 'TRAVLCHQ'
  | 'VALCARDS';

export interface BusinessStructureModel {
  businessStructure: BusinessStructure;
}

export type BusinessStructure = 'A' | 'C' | 'G' | 'P' | 'R' | 'T';

export interface PartyAccountTypeModel {
  accountType: PartyAccountType;
}
export type PartyAccountType =
  | 'BULLION'
  | 'CHEQUE'
  | 'CREDIT'
  | 'CUSTODY'
  | 'FCUR'
  | 'HIRE'
  | 'INS'
  | 'INVEST'
  | 'LOAN'
  | 'PENSION'
  | 'REMIT'
  | 'RETIRE'
  | 'SUPER'
  | 'VALCARD';

/** AUSTRAC XSD Types */

export type AustracYesNo = 'Y' | 'N';
export interface AustracYesNoFormModel {
  yesNo: AustracYesNo;
}

export type AustracCountry = string;
export interface AustracCountryFormModel {
  country: AustracCountry;
}

export type AustracABN = number;
export interface AustracABNFormModel {
  abn: AustracABN;
}

export type AustracACN = number;
export interface AustracACNFormModel {
  acn: AustracACN;
}

export type AustracARBN = number;
export interface AustracARBNFormModel {
  arbn: AustracARBN;
}

export type AustracEmail = string;
export interface AustracEmailFormModel {
  email: AustracEmail;
}

export type AustracPhoneNum = string;
export interface AustracPhoneNumFormModel {
  phoneNum: AustracPhoneNum;
}

export type AustracName = string;
export interface AustracNameFormModel {
  name: AustracName;
}

export type AustracElectronicDataSource = string;
export interface AustracElectronicDataSourceFormModel {
  electDataSrc: AustracElectronicDataSource;
}

export type AustracCustNumber = string;
export interface AustracCustNumberModel {
  custNo: AustracCustNumber;
}

export type AustracAUSTRACDate = string;
export interface AustracAUSTRACDateFormModel {
  date: AustracAUSTRACDate;
}

export type AustracDateOfBirth = string;
export interface AustracDateOfBirthFormModel {
  dob: AustracDateOfBirth;
}

export type AustracCurrencyCode = string;
export interface AustracCurrencyCodeFormModel {
  currency: AustracCurrencyCode;
}
export interface Payee {
  fullName?: string;
  mainAddress?: {
    addr?: string;
    suburb?: string;
    state?: string;
    postcode?: string;
    country?: string;
  };
  account?: [
    {
      bsb?: string;
      number?: string;
    },
  ];
  identification?: [
    {
      type?: string;
      typeOther?: string;
      number?: string;
      issuer?: string;
      country?: string;
    },
  ];
}

export interface Payer {
  fullName?: string;
  mainAddress?: {
    addr?: string;
    suburb?: string;
    state?: string;
    postcode?: string;
    country?: string;
  };
  account?: [
    {
      bsb?: string;
      number?: string;
    },
  ];
  abn?: string;
  acn?: string;
  arbn?: string;
  identification?: [
    {
      type?: string;
      typeOther?: string;
      number?: string;
      issuer?: string;
      country?: string;
    },
  ];
  custNo?: string;
  individualDetails?: {
    dob?: string;
    placeOfBirth?: {
      town?: string;
      country?: string;
    };
  };
}

export type Party = Payer | Payee;
