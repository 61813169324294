import {
  ComponentMap,
  BusinessStructureModel,
  BusinessStructure,
} from '@/editor-map/types';
import { objPathToTitle } from '@/editor-map/title';

const editorType = 'austrac.ifti-dra.BusinessStructure';
const title = objPathToTitle;

// eslint-disable-next-line @typescript-eslint/ban-types
export function toFormSchema(data: string, readonly = false): object {
  return {
    elements: {
      businessStructure: {
        type: 'select',
        label: 'Business Structure',
        options: {
          A: { label: 'A - Association' },
          C: { label: 'C - Company' },
          G: { label: 'G - Government Body' },
          P: { label: 'P - Partnership' },
          R: { label: 'R - Registered Body' },
          T: { label: 'T - Trust' },
        },
        readonly,
      },
    },
  };
}

export function fromFormModel(
  formModel: BusinessStructureModel,
): BusinessStructure {
  return formModel.businessStructure;
}

export function toFormModel(data: string): BusinessStructureModel {
  return {
    businessStructure: data as BusinessStructure,
  };
}

const componentMap: ComponentMap = {
  [editorType]: {
    title,
    toFormModel,
    fromFormModel,
    toFormSchema,
  },
};

export { componentMap };
export default componentMap;
