















































































































































/* eslint-disable @typescript-eslint/ban-types */
// vue
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { ReportEntryEditRequest, ReportItemState } from '@/store';

import { PrPage } from '@/mixin';
import { PrUploaded } from '@/component';
import { I8Accordion, I8Icon, I8Form, downloadFile } from 'i8-ui';

import startCase from 'lodash/startCase';

// Icon library
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle';
import { faSpinner } from '@fortawesome/pro-light-svg-icons/faSpinner';
import { faFileCode } from '@fortawesome/pro-light-svg-icons/faFileCode';

// Add all icons to the library
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faExclamationTriangle, faSpinner, faFileCode);

interface FormModel {
  reportEntry: File;
  justification: string;
}

type DOMParserSupportedType =
  | 'text/xml'
  | 'application/xhtml+xml'
  | 'application/xml'
  | 'image/svg+xml'
  | 'text/html';

export const PrValidationExceptionList = Vue.extend({
  name: 'pr-validation-exception-list',
  mixins: [PrPage],

  components: {
    I8Icon,
    I8Form,
    I8Accordion,
    PrUploaded,
  },

  props: {
    exceptions: {
      type: Array,
      required: true,
    },
    reportItemId: {
      type: String,
      required: true,
      default: '',
    },
  },

  data() {
    return {
      formModel: {} as Record<string, FormModel>,
      formValidation: {},
      fileType: 'text/xml',
      string: {
        pageTitle: 'Report Entry Exceptions',
        reportEntryEditor: {
          title: 'Validation Failed',
          validation: {
            title: 'Validation Message',
          },
          fileDownload: {
            title: 'Download Report Entry',
            helperMsg:
              'Identitii Platform was unable to identify the cause of the error. Please download the report entry, resolve the issue and re-upload',
            downloadBtn: 'Download',
          },
          fileUpload: {
            title: 'Upload Amended Report Entry',
            uploadFieldLabel: 'Amended report entry (.xml)',
            uploadBtn: 'Upload',
            cancelBtn: 'Cancel',
          },
        },
      },
    };
  },

  mounted() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const vm = this as any;
    vm.stateSuccess();
  },

  computed: {
    ...mapGetters('reportItem', ['reportItemDetailsByReportItemId']),

    reportItem(): ReportItemState {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;
      return vm.reportItemDetailsByReportItemId(this.reportItemId);
    },

    formSchema(): object {
      return {
        elements: {
          reportEntry: {
            type: 'file',
            icon: 'file-code',
            label: this.string.reportEntryEditor.fileUpload.uploadFieldLabel,
            required: true,
            accept: '.xml',
          },
          justification: {
            type: 'textarea',
            label: 'Comment',
            required: true,
          },
        },
      };
    },
  },

  methods: {
    ...mapActions('reportItem', ['reportEntryEditRequest']),
    startCase,

    /**
     * Produce a file download from a validation exception
     *
     * @param exception containing the xml data
     */
    downloadReportEntry(exception: Record<string, string>) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;
      const fileName = `${vm.reportItem.transaction_reference}.xml`;
      downloadFile(exception.xml_value, fileName);
    },

    async overrideReportEntry(exception: Record<string, string>) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;
      if (!this.reportItemId) {
        return console.error('Unable to upload report entry: id not found');
      }

      if (!vm.formValidation[exception.type].isValid) {
        // the button should be disabled so if were here, something broke
        // the form should display an error message for the user
        return;
      }

      vm.stateUploading();

      const form = this.formModel[exception.type];

      const file = form.reportEntry;
      if (!file) {
        return console.warn('Report entry file is empty', file);
      }

      if (file.type !== this.fileType) {
        return console.warn('Incorrect file type:', file.type);
      }

      let fileContent: string;
      try {
        fileContent = await this.readFileAsText(file);
      } catch (error) {
        // allow file re-upload after a timeout period
        this.resetState(exception);
        return vm.stateError(error);
      }

      if (!fileContent) {
        // allow file re-upload after a timeout period
        this.resetState(exception);
        return vm.stateError('Report entry file is empty or invalid');
      }

      if (!this.isValidXml(fileContent)) {
        // allow file re-upload after a timeout period
        this.resetState(exception);
        return vm.stateError('Report entry is not valid XML');
      }

      const justification = form.justification;
      if (!justification) {
        // this should be a required field on the form so if we're here,
        // something broke.
        // allow file re-upload after a timeout period
        this.resetState(exception);
        return vm.stateError('Report entry edits require a justification');
      }

      const payload: ReportEntryEditRequest = {
        reportItemId: this.reportItemId,
        edit: { xml_value: fileContent, justification },
      };

      try {
        await vm.reportEntryEditRequest(payload);
      } catch (error) {
        // this is likely unrecoverable
        return vm.stateError(error);
      }
      // successful upload
      vm.stateUploaded();
      this.resetState(exception);
    },

    /**
     * Reset page state after a timeout period
     */
    resetState(exception: Record<string, string>, timeout = 2000) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;
      // ensure the form is cleared
      this.formModel[exception.type] = {
        reportEntry: {} as File,
        justification: '',
      };

      setTimeout(vm.stateSuccess, timeout);
    },

    isValidXml(xml: string): boolean {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(
        xml,
        this.fileType as DOMParserSupportedType,
      );
      return !xmlDoc.getElementsByTagName('parsererror').length;
    },

    async readFileAsText(file: File) {
      return new Promise<string>((resolve, reject) => {
        // File.text() is not supported in IE, so we need to this this method
        const reader = new FileReader();

        reader.onload = () => resolve(reader.result?.toString() as string);

        reader.onerror = reject;

        // start loading file content
        reader.readAsText(file);
      });
    },
  },
});

export default PrValidationExceptionList;
