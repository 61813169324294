/* eslint-disable @typescript-eslint/ban-types */
import { objPathToTitle } from './title';
import { ComponentMap } from './types';
import {
  fromFormModel as i8FromFormModel,
  toFormModel as i8ToFormModel,
} from 'i8-ui';

const editorType = 'austrac.address';
const title = objPathToTitle;

const defaultFormMode = {
  addr: '',
  suburb: '',
  state: '',
  postcode: '',
  country: '',
};

export function toFormSchema(data: object, readonly = false): object {
  return {
    elements: {
      addr: {
        type: 'text',
        label: 'Address',
        placeholder: 'Empty value',
        maxLength: 140,
        readonly,
      },
      suburb: {
        type: 'text',
        label: 'Suburb',
        placeholder: 'Empty value',
        maxLength: 35,
        readonly,
      },
      state: {
        type: 'text',
        label: 'State',
        placeholder: 'Empty value',
        maxLength: 35,
        readonly,
      },
      postcode: {
        type: 'text',
        label: 'Postcode',
        placeholder: 'Empty value',
        maxLength: 15,
        readonly,
      },
      country: {
        type: 'text',
        label: 'Country',
        placeholder: 'Empty value',
        maxLength: 35,
        readonly,
      },
    },
  };
}

export function fromFormModel(
  formModel: object,
  sourceData: object = {},
): object {
  const dataFromModel = i8FromFormModel(formModel, sourceData);
  return dataFromModel;
}

export function toFormModel(data: object): object {
  const model = Object.assign(defaultFormMode, data);

  // users shouldn't be able to edit id fields
  const omitIdProps = true;
  return i8ToFormModel(model, omitIdProps);
}

const componentMap: ComponentMap = {
  [editorType]: {
    title,
    toFormModel,
    fromFormModel,
    toFormSchema,
  },
};

export { componentMap };
export default componentMap;
