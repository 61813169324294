import austracAddress from './austrac.address';
import austracAddressNoCountry from './austrac.address.noCountry';
import austracIftieAddrAcc from './austrac.iftie.addrAcc';
import austracIftieBeneficiaryInstn from './austrac.iftie.beneficiaryInstn';
import austracIftieIdType from './austrac.iftie.IdType';
import austracIftiePayee from './austrac.iftie.payee';
import austracIftiePayer from './austrac.iftie.payer';
import austracIftieSendingInstn from './austrac.iftie.sendingInstn';
import austracPartyAccountType from './austrac.party.account.type';
import austracPartyBusinessStruct from './austrac.party.businessStruct';
import austracTransactionDesignatedSvc from './austrac.transaction.designatedSvc';
import austracTtrIdType from './austrac.ttr.IdType';
import transactionDirection from './transaction.direction';
import unknown from './unknown';

// new
import { editorMap as austracMap } from './austrac';

export const editorMap = {
  ...austracAddress,
  ...austracAddressNoCountry,
  ...austracIftieAddrAcc,
  ...austracIftieBeneficiaryInstn,
  ...austracIftieIdType,
  ...austracIftiePayee,
  ...austracIftiePayer,
  ...austracIftieSendingInstn,
  ...austracPartyAccountType,
  ...austracPartyBusinessStruct,
  ...austracTransactionDesignatedSvc,
  ...austracTtrIdType,
  ...transactionDirection,
  ...unknown,

  ...austracMap,
};

export * from './title';

export * from './austrac';

// for generated json
export * from './editor';
