
































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ReportItemState } from '@/store';

import { I8Quote } from '@/component/i8-quote';

import { downloadFile } from 'i8-ui';

import { DECISION_ORIGINAL_EDIT } from './consts';

export const DecisionOriginalEdit = Vue.extend({
  name: 'decision-original-edit',

  components: {
    I8Quote,
  },

  props: {
    decision: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      decisionType: DECISION_ORIGINAL_EDIT,
      string: {
        validationMessage: {
          title: 'Validation Message',
        },
        original: {
          title: 'Report Entry With Exception',
          downloadBtn: 'Download',
        },
        modified: {
          title: 'Report Entry After Edit',
          downloadBtn: 'Download',
        },
      },
    };
  },

  computed: {
    ...mapGetters('reportItem', ['reportItemDetailsByReportItemId']),

    reportItem(): ReportItemState {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;
      return vm.reportItemDetailsByReportItemId(vm.reportItemId);
    },
  },

  methods: {
    downloadAsFile(content: string, version: string): void {
      downloadFile(content, `report-entry_${version}.xml`);
    },
  },
});

export default DecisionOriginalEdit;
