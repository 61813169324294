



































// vue
import Vue from 'vue';

import { PrPage } from '@/mixin';
import { I8Accordion, I8Icon } from 'i8-ui';

// Icon library
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle';
// Add all icons to the library
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faExclamationTriangle);

export const PrMissingFileList = Vue.extend({
  name: 'pr-missing-file-list',
  mixins: [PrPage],

  components: {
    I8Icon,
    I8Accordion,
  },

  props: {
    missingFiles: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      pageTitle: 'Missing Files',
    };
  },
});

export default PrMissingFileList;
