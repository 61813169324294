import {
  ComponentMap,
  AustracDateOfBirth,
  AustracDateOfBirthFormModel,
} from '@/editor-map/types';
import { objPathToTitle } from '@/editor-map/title';

const editorType = 'austrac.ifti-dra.DateOfBirth';
const title = objPathToTitle;

export function toFormSchema(
  data: AustracDateOfBirth,
  readonly = false,
  // eslint-disable-next-line @typescript-eslint/ban-types
): object {
  return {
    elements: {
      dob: {
        type: 'date',
        format: 'iso',
        label: 'Date',
        required: true,
        placeholder: 'Empty value',
        options: {
          minDate: '1870-01-01',
          maxDate: '2025-12-31',
        },
        readonly,
      },
    },
  };
}

export function fromFormModel(
  formModel: AustracDateOfBirthFormModel,
): AustracDateOfBirth {
  return formModel.dob;
}

export function toFormModel(
  data: AustracDateOfBirth,
): AustracDateOfBirthFormModel {
  return { dob: data };
}

export const componentMap: ComponentMap = {
  [editorType]: {
    title,
    toFormModel,
    fromFormModel,
    toFormSchema,
  },
};

export default componentMap;
