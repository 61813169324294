import { ComponentMap, Payee } from './types';
import { objPathToTitle } from './title';
// import { IdType } from './enum';

const editorType = 'austrac.iftie.payee';
const title = objPathToTitle;

interface FormModel {
  fullName: string;
  mainAddress: {
    addr: string;
    suburb: string;
    state: string;
    postcode: string;
    country: string;
  };
  account: {
    // bsb: string;
    number: string;
  };
  // identification: {
  //   type: string;
  //   typeOther: string;
  //   number: string;
  //   issuer: string;
  //   country: string;
  // };
}

const newPayee: FormModel = {
  fullName: '',
  mainAddress: {
    addr: '',
    suburb: '',
    state: '',
    postcode: '',
    country: '',
  },
  account: {
    // bsb: '',
    number: '',
  },
  // identification: {
  //   type: '',
  //   typeOther: '',
  //   number: '',
  //   issuer: '',
  //   country: '',
  // },
};

export function toFormSchema(
  data: Payee,
  readonly = false,
): Record<string, unknown> {
  return {
    elements: {
      fullName: {
        type: 'text',
        label: 'Full Name',
        placeholder: 'Empty value',
        maxLength: 140,
        readonly,
      },
      mainAddress: {
        type: 'group',
        title: 'Main Address',
        schema: {
          elements: {
            addr: {
              type: 'text',
              label: 'Address',
              placeholder: 'Empty value',
              maxLength: 140,
              readonly,
            },
            suburb: {
              type: 'text',
              label: 'Suburb',
              placeholder: 'Empty value',
              maxLength: 35,
              readonly,
            },
            state: {
              type: 'text',
              label: 'State',
              placeholder: 'Empty value',
              maxLength: 35,
              readonly,
            },
            postcode: {
              type: 'text',
              label: 'Postcode',
              placeholder: 'Empty value',
              maxLength: 15,
              readonly,
            },
            country: {
              type: 'text',
              label: 'Country',
              placeholder: 'Empty value',
              maxLength: 35,
              readonly,
            },
          },
        },
      },
      account: {
        type: 'group',
        title: 'Account 1',
        schema: {
          elements: {
            // bsb: {
            //   type: 'text',
            //   placeholder: 'Empty value',
            //   label: 'BSB',
            //   pattern: '[0-9]{6}',
            //   maxLength: 6,
            //   minLength: 6,
            //   readonly,
            // },
            number: {
              type: 'text',
              label: 'Number',
              placeholder: 'Empty value',
              maxLength: 34,
              readonly,
            },
          },
        },
      },
      // identification: {
      //   type: 'group',
      //   title: 'Identification',
      //   schema: {
      //     elements: {
      //       type: {
      //         type: 'select',
      //         label: 'Type',
      //         options: IdType,
      //         readonly,
      //       },
      //       typeOther: {
      //         type: 'text',
      //         label: 'type Other',
      //         placeholder: 'Empty value',
      //         maxLength: 20,
      //         readonly,
      //       },
      //       number: {
      //         type: 'text',
      //         label: 'Number',
      //         placeholder: 'Empty value',
      //         maxLength: 20,
      //         readonly,
      //       },
      //       issuer: {
      //         type: 'text',
      //         label: 'Issuer',
      //         placeholder: 'Empty value',
      //         maxLength: 30,
      //         readonly,
      //       },
      //       country: {
      //         type: 'text',
      //         label: 'Country',
      //         placeholder: 'Empty value',
      //         maxLength: 35,
      //         readonly,
      //       },
      //     },
      //   },
      // },
    },
  };
}

export function fromFormModel(formModel: FormModel): Payee {
  return {
    ...formModel,
    // identification: [formModel.identification],

    // the onyx mappings only support a single account
    account: [formModel.account],
  };
}

export function toFormModel(data: Payee): FormModel {
  return {
    ...newPayee,
    ...data,

    // the onyx mappings only support a single account
    account: data.account?.length ? data.account[0] : newPayee.account,
    // identification: data.identification?.length
    //   ? data.identification[0]
    //   : newPayee.identification,
  } as FormModel;
}

export const componentMap: ComponentMap = {
  [editorType]: {
    title,
    toFormModel,
    fromFormModel,
    toFormSchema,
  },
};

export default componentMap;
