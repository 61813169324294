










import Vue from 'vue';

import { I8Icon } from 'i8-ui';

// Icon library
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle';
// Add all icons to the library
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faExclamationTriangle);

export const PrException = Vue.extend({
  name: 'pr-exception',

  components: {
    I8Icon,
  },

  props: {
    value: {
      type: String,
      required: true,
      default: '',
    },
  },
});

export default PrException;
