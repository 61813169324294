


































import Vue from 'vue';

import { I8Quote } from '@/component/i8-quote';
import { PrException } from '@/component/pr-exception';

import { DECISION_EXCEPTION_IGNORE } from './consts';

export const DecisionExceptionIgnore = Vue.extend({
  name: 'decision-exception-ignore',

  components: {
    I8Quote,
    PrException,
  },

  props: {
    decision: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      decisionType: DECISION_EXCEPTION_IGNORE,
    };
  },

  computed: {
    reportItemId(): string {
      return this.$route.params.id;
    },

    // When we ignore reportable-with-exception, we actually display the exception as 'Confirmed as reportable' rather than 'Ignored'
    isConfirmReportable(): boolean {
      return (
        this.decision[this.decisionType].exception.id ===
        'reportable-with-exception'
      );
    },
  },
});

export default DecisionExceptionIgnore;
