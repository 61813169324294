import { ComponentMap, Payer } from './types';
import { objPathToTitle } from './title';
// import { IdType } from './enum';

const editorType = 'austrac.iftie.payer';
const title = objPathToTitle;

interface FormModel {
  fullName: string;
  mainAddress: {
    addr: string;
    suburb: string;
    state: string;
    postcode: string;
    country: string;
  };
  account: {
    bsb?: string;
    number: string;
  };
  // abn: string;
  // acn: string;
  // arbn: string;
  // identification: {
  //   type: string;
  //   typeOther: string;
  //   number: string;
  //   issuer: string;
  //   country: string;
  // };
  // custNo: string;
  // individualDetails: {
  //   dob?: string;
  //   placeOfBirth: {
  //     town: string;
  //     country: string;
  //   };
  // };
}

const newPayer: FormModel = {
  fullName: '',
  mainAddress: {
    addr: '',
    suburb: '',
    state: '',
    postcode: '',
    country: '',
  },
  account: {
    // bsb: '',
    number: '',
  },
  // abn: '',
  // acn: '',
  // arbn: '',
  // identification: {
  //   type: '',
  //   typeOther: '',
  //   number: '',
  //   issuer: '',
  //   country: '',
  // },
  // custNo: '',
  // individualDetails: {
  //   dob: undefined,
  //   placeOfBirth: {
  //     town: '',
  //     country: '',
  //   },
  // },
};

export function toFormSchema(
  data: string,
  readonly = false,
): Record<string, unknown> {
  return {
    elements: {
      fullName: {
        type: 'text',
        label: 'Full Name',
        placeholder: 'Empty value',
        maxLength: 140,
        readonly,
      },
      // custNo: {
      //   type: 'text',
      //   label: 'Customer Number',
      //   placeholder: 'Empty value',
      //   maxLength: 35,
      //   readonly,
      // },
      // abn: {
      //   type: 'text',
      //   label: 'ABN',
      //   placeholder: 'Empty value',
      //   maxLength: 11,
      //   minLength: 11,
      //   readonly,
      // },
      // acn: {
      //   type: 'text',
      //   label: 'ACN',
      //   placeholder: 'Empty value',
      //   maxLength: 9,
      //   minLength: 9,
      //   readonly,
      // },
      // arbn: {
      //   type: 'text',
      //   label: 'ARBN',
      //   placeholder: 'Empty value',
      //   maxLength: 9,
      //   minLength: 9,
      //   readonly,
      // },
      mainAddress: {
        type: 'group',
        title: 'Main Address',
        schema: {
          elements: {
            addr: {
              type: 'text',
              label: 'Address',
              placeholder: 'Empty value',
              maxLength: 140,
              readonly,
            },
            suburb: {
              type: 'text',
              label: 'Suburb',
              placeholder: 'Empty value',
              maxLength: 35,
              readonly,
            },
            state: {
              type: 'text',
              label: 'State',
              placeholder: 'Empty value',
              maxLength: 35,
              readonly,
            },
            postcode: {
              type: 'text',
              label: 'Postcode',
              placeholder: 'Empty value',
              maxLength: 15,
              readonly,
            },
            country: {
              type: 'text',
              label: 'Country',
              placeholder: 'Empty value',
              maxLength: 35,
              readonly,
            },
          },
        },
      },
      account: {
        type: 'group',
        title: 'Account 1',
        schema: {
          elements: {
            // bsb: {
            //   type: 'text',
            //   placeholder: 'Empty value',
            //   label: 'BSB',
            //   pattern: '[0-9]{6}',
            //   maxLength: 6,
            //   minLength: 6,
            //   readonly,
            // },
            number: {
              type: 'text',
              label: 'Number',
              placeholder: 'Empty value',
              maxLength: 34,
              readonly,
            },
          },
        },
      },
      // identification: {
      //   type: 'group',
      //   title: 'Identification',
      //   schema: {
      //     elements: {
      //       type: {
      //         type: 'select',
      //         label: 'Type',
      //         options: IdType,
      //         readonly,
      //       },
      //       typeOther: {
      //         type: 'text',
      //         label: 'type Other',
      //         placeholder: 'Empty value',
      //         maxLength: 20,
      //         readonly,
      //       },
      //       number: {
      //         type: 'text',
      //         label: 'Number',
      //         placeholder: 'Empty value',
      //         maxLength: 20,
      //         readonly,
      //       },
      //       issuer: {
      //         type: 'text',
      //         label: 'Issuer',
      //         placeholder: 'Empty value',
      //         maxLength: 30,
      //         readonly,
      //       },
      //       country: {
      //         type: 'text',
      //         label: 'Country',
      //         placeholder: 'Empty value',
      //         maxLength: 35,
      //         readonly,
      //       },
      //     },
      //   },
      // },
      // individualDetails: {
      //   type: 'group',
      //   title: 'Individual Details',
      //   schema: {
      //     elements: {
      //       dob: {
      //         type: 'date',
      //         label: 'Date of Birth',
      //         format: 'iso',
      //         placeholder: 'Empty value',
      //         readonly,
      //       },
      //       placeOfBirth: {
      //         type: 'group',
      //         title: 'Place of Birth',
      //         schema: {
      //           elements: {
      //             town: {
      //               type: 'text',
      //               label: 'Town',
      //               placeholder: 'Empty value',
      //               maxLength: 35,
      //               readonly,
      //             },
      //             country: {
      //               type: 'text',
      //               label: 'Country',
      //               placeholder: 'Empty value',
      //               maxLength: 35,
      //               readonly,
      //             },
      //           },
      //         },
      //       },
      //     },
      //   },
      // },
    },
  };
}

export function fromFormModel(formModel: FormModel): Payer {
  return {
    ...formModel,
    // identification: [formModel.identification],

    // the onyx mappings only support a single account
    account: [formModel.account],
  };
}

export function toFormModel(data: Payer): FormModel {
  return {
    ...newPayer,
    ...data,
    // the onyx mappings only support a single account
    account: data.account?.length ? data.account[0] : newPayer.account,
    // identification: data.identification?.length
    //   ? data.identification[0]
    //   : newPayer.identification,
  } as FormModel;
}

export const componentMap: ComponentMap = {
  [editorType]: {
    title,
    toFormModel,
    fromFormModel,
    toFormSchema,
  },
};

export default componentMap;
