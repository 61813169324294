/* eslint-disable @typescript-eslint/ban-types */
import { ComponentMap } from '@/editor-map/types';
import { toFormModel as i8ToFormModel } from 'i8-ui';
import { objPathToTitle } from '@/editor-map/title';
import { IdentificationTypeModel } from '@/editor-map/types';

const editorType = 'austrac.ifti-dra.IdentificationNoCountry';
const title = objPathToTitle;

export function toFormSchema(data: string, readonly = false): object {
  return {
    elements: {
      type: {
        type: 'select',
        label: 'Identification Type',
        options: {
          A: { label: 'A - Bank Account' },
          C: { label: 'C - Credit card/debit card' },
          D: { label: "D - Driver's License" },
          P: { label: 'P - Passport' },
          T: { label: 'T - Telephone/fax number' },
          ARNU: { label: 'ARNU - Alien registration/license' },
          CUST: { label: 'CUST - Customer account/ID' },
          BENE: { label: 'BENE - Benefits card/ID' },
          BCNO: { label: 'BCNO - Birth certificate' },
          BUSR: { label: 'BUSR - Business registration/license' },
          EMID: { label: 'EMID - Employee ID' },
          EMPL: { label: 'EMPL - Employee number' },
          IDNT: { label: 'IDNT - Identity card or National identity number' },
          MEMB: { label: 'MEMB - Membership ID' },
          PHOT: { label: 'PHOT - Photo ID' },
          SECU: { label: 'SECU - Security ID' },
          SOSE: { label: 'SOSE - Social security ID' },
          STUD: { label: 'STUD - Student ID' },
          TXID: { label: 'TXID - Tax number/ID' },
        },
        readonly,
      },
      typeOther: {
        type: 'text',
        label: 'Type Other',
        placeholder: 'Empty value',
        readonly,
      },
      number: {
        type: 'text',
        label: 'Number',
        maxlength: 20,
        placeholder: 'Empty value',
        readonly,
      },
      issuer: {
        type: 'text',
        label: 'Issuer',
        maxlength: 30,
        placeholder: 'Empty value',
        readonly,
      },
    },
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fromFormModel(formModel: IdentificationTypeModel): any {
  return formModel;
}

export function toFormModel(data: object): Record<string, string> {
  // users shouldn't be able to edit id fields
  const omitIdProps = true;
  return i8ToFormModel(data, omitIdProps);
}

const componentMap: ComponentMap = {
  [editorType]: {
    title,
    toFormModel,
    fromFormModel,
    toFormSchema,
  },
};

export { componentMap };
export default componentMap;
