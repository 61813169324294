import { ComponentMap, PartyAccountTypeModel, PartyAccountType } from './types';
import { objPathToTitle } from './title';

const editorType = 'austrac.party.account.type';
const title = objPathToTitle;

// eslint-disable-next-line @typescript-eslint/ban-types
export function toFormSchema(data: string, readonly = false): object {
  return {
    elements: {
      accountType: {
        type: 'select',
        label: 'Account Type',
        options: {
          BULLION: { label: 'BULLION - Bullion account' },
          CHEQUE: { label: 'CHEQUE - Cheque or savings account' },
          CREDIT: { label: 'CREDIT - Credit card account' },
          CUSTODY: { label: 'CUSTODY - Custodial account' },
          FCUR: { label: 'FCUR - Foreign currency account' },
          HIRE: { label: 'HIRE - Lease/hire purchase account' },
          INS: { label: 'INS - Insurance policy' },
          INVEST: { label: 'INVEST - Investment account' },
          LOAN: { label: 'LOAN - Loan or mortgage account' },
          PENSION: { label: 'PENSION - Pension/annuity account' },
          REMIT: { label: 'REMIT - Remittance account' },
          RETIRE: { label: 'RETIRE - Retirement savings account' },
          SUPER: {
            label:
              'SUPER - Superannuation or approved deposit fund (ADF) account',
          },
          VALCARD: { label: 'VALCARD - Stored value card account' },
        },
        readonly,
      },
    },
  };
}

export function fromFormModel(
  formModel: PartyAccountTypeModel,
): PartyAccountType {
  return formModel.accountType;
}

export function toFormModel(data: string): PartyAccountTypeModel {
  // users shouldn't be able to edit id fields
  return {
    accountType: data as PartyAccountType,
  };
}

const componentMap: ComponentMap = {
  [editorType]: {
    title,
    toFormModel,
    fromFormModel,
    toFormSchema,
  },
};

export { componentMap };
export default componentMap;
