import {
  I8FormOptions,
  ComponentMap,
  AustracCountryFormModel,
  AustracCountry,
} from '@/editor-map/types';
import { objPathToTitle } from '@/editor-map/title';
import { countriesIso3166 } from '@/util';

const editorType = 'austrac.ifti-dra.Country';
const title = objPathToTitle;

// eslint-disable-next-line @typescript-eslint/ban-types
export function toFormSchema(data: string, readonly = false): object {
  const options = countriesIso3166.reduce((options, country) => {
    options[country] = { label: country };
    return options;
  }, {} as I8FormOptions);

  return {
    elements: {
      country: {
        type: 'select',
        label: 'Country',
        options,
        readonly,
      },
    },
  };
}

export function fromFormModel(
  formModel: AustracCountryFormModel,
): AustracCountry {
  return formModel.country;
}

export function toFormModel(data: AustracCountry): AustracCountryFormModel {
  return {
    country: data,
  };
}

const componentMap: ComponentMap = {
  [editorType]: {
    title,
    toFormModel,
    fromFormModel,
    toFormSchema,
  },
};

export { componentMap };
export default componentMap;
