



















import Vue from 'vue';

import { I8Icon } from 'i8-ui';

// Icon library
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle';
// Add all icons to the library
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faCheckCircle);

const PrUploaded = Vue.extend({
  name: 'pr-uploaded',

  components: {
    I8Icon,
  },
});

export { PrUploaded };
export default PrUploaded;
