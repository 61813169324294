import {
  ComponentMap,
  AustracARBN,
  AustracARBNFormModel,
} from '@/editor-map/types';
import { objPathToTitle } from '@/editor-map/title';

const editorType = 'austrac.ifti-dra.ARBN';
const title = objPathToTitle;

// eslint-disable-next-line @typescript-eslint/ban-types
export function toFormSchema(data: AustracARBN, readonly = false): object {
  return {
    elements: {
      arbn: {
        type: 'number',
        maxlength: 9,
        minlength: 9,
        pattern: '[0-9]{9}',
        label: 'ARBN',
        required: true,
        placeholder: 'Empty value',
        readonly,
      },
    },
  };
}

export function fromFormModel(formModel: AustracARBNFormModel): string {
  return formModel.arbn.toString();
}

export function toFormModel(data: AustracARBN): AustracARBNFormModel {
  return { arbn: data };
}

export const componentMap: ComponentMap = {
  [editorType]: {
    title,
    toFormModel,
    fromFormModel,
    toFormSchema,
  },
};

export default componentMap;
