/* eslint-disable @typescript-eslint/ban-types */
import { objPathToTitle } from '@/editor-map/title';
import { ComponentMap } from '@/editor-map/types';
import {
  fromFormModel as i8FromFormModel,
  toFormModel as i8ToFormModel,
} from 'i8-ui';

const editorType = 'austrac.ifti-dra.BranchOptCountry';
const title = objPathToTitle;

export function toFormSchema(data: object, readonly = false): object {
  return {
    elements: {
      branchId: {
        type: 'text',
        label: 'Branch ID',
        placeholder: 'Empty value',
        maxLength: 35,
        readonly,
      },
      fullName: {
        type: 'text',
        label: 'Branch Name',
        placeholder: 'Empty value',
        maxLength: 120,
        readonly,
      },
      mainAddress: {
        addr: {
          type: 'text',
          label: 'Address',
          placeholder: 'Empty value',
          maxLength: 140,
          readonly,
        },
        suburb: {
          type: 'text',
          label: 'Suburb',
          placeholder: 'Empty value',
          maxLength: 35,
          readonly,
        },
        state: {
          type: 'text',
          label: 'State',
          placeholder: 'Empty value',
          maxLength: 35,
          readonly,
        },
        postcode: {
          type: 'text',
          label: 'Postcode',
          placeholder: 'Empty value',
          maxLength: 15,
          readonly,
        },
      },
    },
  };
}

export function fromFormModel(
  formModel: object,
  sourceData: object = {},
): object {
  return i8FromFormModel(formModel, sourceData);
}

export function toFormModel(data: object): object {
  // users shouldn't be able to edit id fields
  const omitIdProps = true;
  return i8ToFormModel(data, omitIdProps);
}

const componentMap: ComponentMap = {
  [editorType]: {
    title,
    toFormModel,
    fromFormModel,
    toFormSchema,
  },
};

export { componentMap };
export default componentMap;
