






import Vue from 'vue';

export const PrBadge = Vue.extend({
  name: 'pr-badge',

  props: {
    value: {
      type: [String, Number],
      required: true,
    },
  },
});

export default PrBadge;
